type NextButtonProps = {
  text: string;
};

const NextButton = ({ text }: NextButtonProps) => (
  <button
    className={`h-16 max-w-full w-full font-semibold rounded-r-sm py-2 px-4 mt-6 lg:mt-8 transition-all duration-300 text-lg focus-visible:outline-none bg-button-background-color text-button-text-color hover:opacity-95`}
    type="submit"
    id="next-button"
    data-testid="next-step-button"
  >
    {text}
  </button>
);

export default NextButton;
