import { useEffect, useState } from 'react';
import { getTime } from './Deadline.helpers';

const useDeadlineTime = (startingTimeLeft?: number) => {
  const [timeLeft, setTimeLeft] = useState<number>();

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (timeLeft === undefined) {
      !!startingTimeLeft && setTimeLeft(startingTimeLeft);
      return;
    }

    if (timeLeft > 0 && timeLeft !== null) {
      timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    }

    return () => clearTimeout(timer);
  }, [startingTimeLeft, timeLeft]);

  if (timeLeft === undefined) {
    return null;
  }

  return !!timeLeft && timeLeft > 0 ? getTime(timeLeft) : getTime(0);
};

export default useDeadlineTime;
