import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';

import { IContactInformationComponent } from '../models/elements/contactInformation';
import { Input } from './Input';
import { EmailField } from './EmailField';

import { usePageStore } from '@/src/common/zustand';

import { NextRouter, useRouter } from 'next/router';
import { PageStore } from '../../zustand/types';

const getFieldData = (name, value, t) => {
  if (value === 'no' || value === false) {
    return false;
  }
  const textOptional = ` (${t('optional')})`;

  switch (name) {
    case 'email':
      return {
        id: name,
        name,
        type: 'email',
        placeholder: t('emailAddress'),
        registerOptions: {
          required: true,
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: t('invalidEmailAddress'),
          },
        },
        autocomplete: 'email',
        errorName: t('emailAddress'),
      };
    case 'business_name':
      return {
        id: name,
        name,
        type: 'text',
        placeholder: `${t('companyName')}${
          value === 'optional' ? textOptional : ''
        }`,
        registerOptions: {
          required: value === 'optional' ? false : true,
          minLength: 2,
        },
        autocomplete: 'organization',
        errorName: t('companyName'),
      };
    case 'first_name':
      return {
        id: name,
        name,
        type: 'text',
        placeholder: t('firstName'),
        registerOptions: {
          required: true,
          minLength: 2,
        },
        errorName: t('firstName'),
        autocomplete: 'given-name',
      };
    case 'last_name':
      return {
        id: name,
        name,
        type: 'text',
        placeholder: t('lastName'),
        registerOptions: {
          required: true,
          minLength: 2,
        },
        errorName: t('lastName'),
        autocomplete: 'family-name',
      };
    case 'customers_telephone':
      return {
        id: 'phone',
        name: 'phone',
        type: 'tel',
        placeholder: `${t('phone')}${value === 'optional' ? textOptional : ''}`,
        registerOptions: {
          required: value === 'optional' ? false : true,
          minLength: 9,
        },
        autocomplete: 'tel',
        errorName: t('telephone'),
      };
    case 'password':
      return {
        id: name,
        name,
        type: 'password',
        placeholder: `${t('createPassword')}${textOptional}`,
        registerOptions: {
          required: true,
          minLength: 6,
        },
        autocomplete: 'new-password',
        errorName: t('password'),
      };
  }

  return;
};

const sortFields = (fields) => {
  let sortedFields: any = [];

  'business_name' in fields && sortedFields.push(fields.business_name);
  'first_name' in fields && sortedFields.push(fields.first_name);
  'last_name' in fields && sortedFields.push(fields.last_name);
  'email' in fields && sortedFields.push(fields.email);
  'password' in fields && sortedFields.push(fields.password);
  'customers_telephone' in fields &&
    sortedFields.push(fields.customers_telephone);

  return sortedFields;
};

const getFieldsWithNameAndEmail = (fields, addressEnabled) => {
  let fieldsWithNameAndEmail = [];
  fieldsWithNameAndEmail['email'] = 'required';

  Object.keys(fields).map((field) => {
    if (field !== 'customers_name') {
      fieldsWithNameAndEmail[field] = fields[field];
    } else if (
      field === 'customers_name' &&
      fields[field] != 'no' &&
      !addressEnabled
    ) {
      fieldsWithNameAndEmail['first_name'] = 'required';
      if (fields[field] === 'full_name') {
        fieldsWithNameAndEmail['last_name'] = 'required';
      }
    }
  });
  return fieldsWithNameAndEmail;
};

const Fields = ({
  customerPortal = false,
  fields,
  register,
  errors,
  prefilledEmail = false,
  addressEnabled,
}) => {
  const { t } = useTranslation('common');
  const fieldsWithNameAndEmail = getFieldsWithNameAndEmail(
    fields,
    addressEnabled
  );

  let fieldsWithData = [];

  Object.keys(fieldsWithNameAndEmail).map((field) => {
    const fieldData = getFieldData(field, fields[field], t);

    if (fieldData) {
      fieldsWithData[field] = fieldData;
    }
  });

  const firstNameAndLastName =
    'first_name' in fieldsWithNameAndEmail &&
    'last_name' in fieldsWithNameAndEmail;

  const sortedFields = sortFields(fieldsWithData);

  const fieldItems = sortedFields.map((field, index) => {
    const isNameField =
      field.name === 'first_name' || field.name === 'last_name' ? true : false;
    const isEmailField = field.name === 'email' ?? false;

    return isEmailField ? (
      <div
        className={`bg-white my-2 lg:my-3 ${
          isNameField && firstNameAndLastName
            ? 'w-[48%] lg:w-[49%] flex-initial'
            : 'w-full flex-auto'
        }`}
        key={index}
      >
        <EmailField
          id={field.id}
          name={field.name}
          type={field.type}
          placeholder={field.placeholder}
          register={() => register(field.id, field.registerOptions)}
          registerOptions={field.registerOptions}
          errors={errors[field.id] ?? null}
          autocomplete={field.autocomplete}
          errorName={field.errorName}
          disabled={isEmailField && !!(customerPortal || prefilledEmail)}
          prefilled={prefilledEmail}
        />
      </div>
    ) : (
      <div
        className={`bg-white my-2 lg:my-3 ${
          isNameField && firstNameAndLastName
            ? 'w-[48%] lg:w-[49%] flex-initial'
            : 'w-full flex-auto'
        }`}
        key={index}
      >
        <Input
          id={field.id}
          name={field.name}
          type={field.type}
          placeholder={field.placeholder}
          register={() => register(field.id, field.registerOptions)}
          registerOptions={field.registerOptions}
          errors={errors[field.id] ?? null}
          autocomplete={field.autocomplete}
          errorName={field.errorName}
          disabled={
            field.name === 'email' && !!(customerPortal || prefilledEmail)
          }
        />
      </div>
    );
  });

  return (
    <div className="flex flex-wrap gap-x-[12px] lg:gap-x-[10px]">
      {fieldItems}
    </div>
  );
};

interface IProps {
  fields: object | IContactInformationComponent;
  customerPortal?: boolean;
  addressEnabled?: boolean;
  evergreenDeadlineEnabled?: boolean;
}

const ContactInformation = ({
  fields = {},
  customerPortal = false,
  addressEnabled,
  evergreenDeadlineEnabled,
}: IProps) => {
  const contactInformationValues = usePageStore(
    (state) => state.contactInformationValues
  );
  const updateContactInformationValues = usePageStore(
    (state) => state.updateContactInformationValues
  );

  const { t } = useTranslation('common');

  const router: NextRouter = useRouter();
  const vEmail = router?.query?.vemail as string | undefined;

  const {
    register,
    formState,
    formState: { errors, isValid },
    trigger,
    watch,
    reset,
  } = useForm({
    mode: 'all',
    defaultValues: contactInformationValues!,
  });

  const updateContactInformationState = usePageStore(
    (state) => state.updateContactInformation
  );

  const deadlineInfo = usePageStore((state) => state.deadlineInfo);

  const updateEmailValue = (value: string): void => {
    const newContactFormValues = {
      ...contactInformationValues,
      email: value,
    };
    reset(newContactFormValues);
    updateContactInformationValues({ email: value });
  };

  useEffect(() => {
    if (vEmail && !evergreenDeadlineEnabled) {
      updateEmailValue(vEmail.replace(new RegExp(/ +/, 'g'), '+'));
    }
  }, [vEmail]);

  useEffect(() => {
    if (deadlineInfo?.email && evergreenDeadlineEnabled) {
      updateEmailValue(deadlineInfo.email);
    }
  }, [deadlineInfo, evergreenDeadlineEnabled]);

  useEffect(() => {
    updateContactInformationState(formState);
  }, [isValid]);

  useEffect(() => {
    const subscription = watch((value) => {
      // Remove first_name and last_name keys from value if addressEnabled = true
      if (addressEnabled) {
        delete value.first_name;
        delete value.last_name;
      }
      updateContactInformationValues(value);
    });
    return () => subscription.unsubscribe();
  }, [watch, addressEnabled]);

  useEffect(() => {
    reset(); // https://github.com/react-hook-form/react-hook-form/issues/2492#issuecomment-904234100

    const unsubscribe = usePageStore.subscribe(
      (state) => (state as PageStore).triggerFormValidation,
      (triggerFormValidation) => {
        if (triggerFormValidation) {
          trigger();
        }
      }
    );
    return () => unsubscribe();
  }, []);

  return (
    <div className="w-full mt-6 lg:mt-8 max-w-ful">
      <h2 className="text-xl sm:text-2xl">{t('contactInformation')}</h2>
      <Fields
        customerPortal={customerPortal}
        fields={fields}
        register={register}
        errors={errors}
        prefilledEmail={
          (!!vEmail && !evergreenDeadlineEnabled) ||
          (!!deadlineInfo?.email && evergreenDeadlineEnabled)
        }
        addressEnabled={addressEnabled}
      />
    </div>
  );
};

export default ContactInformation;
