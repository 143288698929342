import create from 'zustand';

interface CurrencyCodeState {
  currencyCode: string;
  setCurrencyCode: (value: string) => void;
}

const useCurrencyCodeStore = create<CurrencyCodeState>((set) => ({
  currencyCode: '',
  setCurrencyCode: (value) => set({ currencyCode: value }),
}));

export default useCurrencyCodeStore;
