import * as Sentry from '@sentry/nextjs';

import Error from 'next/error';
import Script from 'next/script';
import Head from 'next/head';
import dynamic from 'next/dynamic';

import axios, { AxiosResponse } from 'axios';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { default as OfferPage } from '@/src/components/Offer';
import { getDeadlineFunnelTrackingCode } from '@/src/common/js/utils';
import { IPrebuildPaymentPageDataItem } from '@/src/modules/payment-page/models/responsePaymentPage';
import { paths } from '@/src/schemas/offer';
import { API } from '@/src/config';
import { StrictMode } from 'react';

import * as EncryptionService from '@/src/services/EncryptionService';

declare global {
  interface Window {
    grecaptcha: {
      ready: (arg: () => void) => void;
      execute: (
        key: string | undefined,
        config: { action: string }
      ) => {
        then: (callback: (token: string) => void) => string;
      };
    };
    rewardful: (status: string, callback: () => void) => void;
    Rewardful: {
      referral: string;
    };
    LinkMink: (key: string) => {
      getReferralData: () => string | null;
      initTracking: () => Promise<void>;
    };
    fbq: (
      ev: string,
      evName: string,
      data: { [key: string | number]: any }
    ) => void;
    dataLayer: Array<{ [key: string]: any }>;
  }
}

const MetaAndScripts = dynamic(
  () => import('@/components/MetaAndScripts').then((mod) => mod.MetaAndScripts),
  { ssr: false }
);

export type Offer =
  paths['/ssr/hosts/{host}/offers/{slug}']['get']['responses']['200']['content']['application/json'];

interface IProps {
  offer: Offer;
  encryptedOffer: string;
  locale: object;
  errorStatus: number;
  faviconUrl: string;
  globalCodeSnippet: string;
  url?: string;
  accountId: string;
  paymentPageId: string;
  canonicalUrl?: string;
  dfTrackingCode?: string;
  isE2ETestOffer: boolean;
}

export async function getStaticPaths() {
  return {
    paths: [],
    fallback: 'blocking',
  };
}

const getOffer = async (hostname: string, slug: string): Promise<Offer> => {
  const host =
    process.env.APP_ENV === 'development'
      ? `${hostname}.vomapagesdev.com`
      : hostname;
  const url = `${API}/ssr/hosts/${host}/offers/${slug}`;
  const headers = {
    Accept: 'application/json',
    Authorization: process.env.API_TOKEN!,
    'Content-Type': 'application/json',
  };
  const offer: Offer = await axios
    .get(url, { headers })
    .then((response: AxiosResponse) => response.data)
    .catch((error) => {
      console.error(error);
      return null;
    });

  return offer;
};

export async function getStaticProps({
  params: { host, slug },
}: {
  params: IPrebuildPaymentPageDataItem;
}) {
  let offer: Offer | null = null;
  let encryptedOffer: string | null = null;
  let dfTrackingCode = null;

  if (!host || !slug) {
    return {
      props: { errorStatus: 404 },
      revalidate: 30,
    };
  }

  try {
    offer = await getOffer(host, slug);
    if (offer) {
      encryptedOffer = EncryptionService.encrypt(JSON.stringify(offer));
    }
  } catch (error) {
    Sentry.captureException(error);
    return {
      props: { errorStatus: error.status || 500 },
    };
  }

  if (!offer || !offer.account || offer?.status === 'disabled') {
    return { props: { errorStatus: 404 }, revalidate: 30 };
  } else if (
    !offer.account.integrations ||
    offer.account.integrations.length === 0
  ) {
    return { props: { errorStatus: 500 }, revalidate: 30 };
  }

  const isE2ETestOffer = [
    'vomademo',
    'vomae2estaging',
    'vomae2estagingnotax',
    'vomae2eprod',
    'vomae2eprodnotax',
  ].includes(offer.account.slug);

  let canonicalUrl = `https://${offer.account.slug}.${process.env.PURCHASE_HOST}`;
  let globalCodeSnippet = offer.account.global_code_snippet;
  let faviconUrl = offer.account.favicon_url;
  const url = `https://${host}/${slug}`;
  const customDomain = offer.account.custom_domain ?? false;
  const dfIntegration = offer.account.integrations.find(
    (i) => i.system_name === 'Deadline Funnel'
  );

  if (customDomain && customDomain.startsWith('http')) {
    canonicalUrl = customDomain;
  }
  canonicalUrl += `/${slug}`;

  if (dfIntegration) {
    const userIdHash = dfIntegration.credentials['user_id_hash'];
    const trackingEnabled = dfIntegration.options['tracking_enabled'];
    if (trackingEnabled && userIdHash) {
      dfTrackingCode = getDeadlineFunnelTrackingCode(userIdHash);
    }
  }

  const lang = offer?.customization?.language || 'en';

  return {
    props: {
      offer,
      encryptedOffer,
      faviconUrl,
      accountId: offer.account.id,
      paymentPageId: slug,
      url,
      canonicalUrl,
      globalCodeSnippet,
      dfTrackingCode,
      isE2ETestOffer,
      ...(await serverSideTranslations(lang, [
        'common',
        'paymentPage',
        'paymentOptions',
        'upsell',
        'thankYouPage',
        'prices',
      ])),
    },
    revalidate: 30,
  };
}

export default function Pages({
  offer,
  encryptedOffer,
  accountId,
  paymentPageId,
  url,
  canonicalUrl,
  globalCodeSnippet,
  dfTrackingCode,
  isE2ETestOffer,
  faviconUrl,
  errorStatus = 0,
}: IProps) {
  if (errorStatus >= 400 || !offer) {
    return <Error statusCode={errorStatus >= 400 ? errorStatus : 404} />;
  }

  /*
   * Data for Facebook, GTM, Rewardful, Linkmink, IP, SEO & Social, Page Visits
   */
  const MetaAndScriptsArgs = {
    offer,
    integrations: offer.account.integrations,
    accountId,
    paymentPageId,
    seoSocial: offer.customization?.seo_social,
    font: offer.customization?.custom_fields?.fonts?.headline,
    productName: offer.product?.name,
    paymentPageProductName: offer.product?.name,
    mainProductLabel: offer.main_product_label,
    mainProductTitle: offer.product?.title,
    mainProductName: offer.product?.name,
    globalCodeSnippet,
    dfTrackingCode,
    faviconUrl,
    canonicalUrl,
    url,
  };

  // Head Args
  const { seo_title, seo_description, block_search_index, social_image } =
    MetaAndScriptsArgs.seoSocial!;

  // Page title
  const pageTitle =
    seo_title ||
    MetaAndScriptsArgs.mainProductLabel ||
    MetaAndScriptsArgs.mainProductTitle ||
    MetaAndScriptsArgs.productName;

  let globalCode = '';
  if (MetaAndScriptsArgs.globalCodeSnippet) {
    globalCode = MetaAndScriptsArgs.globalCodeSnippet.replace(
      /<script[\s\S]*?>/,
      ''
    );
    globalCode = globalCode.replace('</script>', '');
  }

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="description" content={seo_description || ''} />
        {block_search_index && <meta name="robots" content="noindex" />}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={seo_description || ''} />
        {social_image && <meta property="og:image" content={social_image} />}
        <link
          rel="shortcut icon"
          type="image/x-icon"
          href={
            MetaAndScriptsArgs.faviconUrl || '/static/assets/images/favicon.png'
          }
        />
        <link rel="canonical" href={MetaAndScriptsArgs.canonicalUrl} />
        {MetaAndScriptsArgs.font && (
          <link
            href={`https://fonts.googleapis.com/css2?family=${MetaAndScriptsArgs.font}`}
            rel="stylesheet"
          />
        )}
        {MetaAndScriptsArgs.globalCodeSnippet && (
          <script
            dangerouslySetInnerHTML={{
              __html: globalCode,
            }}
          />
        )}
      </Head>
      <StrictMode>
        <OfferPage offer={offer} encryptedOffer={encryptedOffer} isE2ETestOffer={isE2ETestOffer} />
      </StrictMode>
      <MetaAndScripts
        offer={MetaAndScriptsArgs.offer}
        paymentPageId={MetaAndScriptsArgs.paymentPageId}
      />
      <Script
        src={`https://www.google.com/recaptcha/api.js?render=${process.env.RECAPTCHAV3_SITE_KEY}`}
        strategy="lazyOnload"
      />
      {MetaAndScriptsArgs.dfTrackingCode && (
        <Script id={'df-voma-script'}>
          {MetaAndScriptsArgs.dfTrackingCode}
        </Script>
      )}
    </>
  );
}
