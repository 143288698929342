import TestModeSvg from '@/src/common/components/svg/TestModeSvg';

const TestModeBanner = ({
  textBefore,
  textAfter,
}: {
  textBefore: string;
  textAfter: string;
}): JSX.Element => (
  <div
    className="width-full bg-[#ff5466] text-white text-center shadow-md text-sm flex p-3 justify-center"
    data-testid="test-banner"
  >
    <TestModeSvg className="test-shape" />
    <span className="ml-2">
      <span className="font-semibold">{textBefore}</span>
      {' - '}
      {textAfter}
    </span>
  </div>
);

export default TestModeBanner;
