import { FC } from 'react';

const TwoArrowsDownIcon: FC<{ className: string; color?: string }> = ({
  className = '',
  color = '#2d2d2d',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="12"
    viewBox="0 0 15 12"
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.56437 6.808L14.2665 2.136C14.5778 1.864 14.5778 1.448 14.2665 1.192C13.9551 0.936 13.4296 0.936 13.1183 1.192L8 5.4L2.88174 1.192C2.5509 0.936 2.04491 0.936 1.73353 1.192C1.42216 1.448 1.42216 1.864 1.73353 2.136L7.41617 6.808C7.74701 7.064 8.25299 7.064 8.56437 6.808Z"
      fill={color}
      fillOpacity="0.8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.56437 10.808L14.2665 6.136C14.5778 5.864 14.5778 5.448 14.2665 5.192C13.9551 4.936 13.4296 4.936 13.1183 5.192L8 9.4L2.88174 5.192C2.5509 4.936 2.04491 4.936 1.73353 5.192C1.42216 5.448 1.42216 5.864 1.73353 6.136L7.41617 10.808C7.74701 11.064 8.25299 11.064 8.56437 10.808Z"
      fill={color}
    />
  </svg>
);

export default TwoArrowsDownIcon;
