import { StripeProduct } from '@/src/common/components/models/responsePaymentPage';
import { useOfferStore, usePageStore } from '@/src/common/zustand';
import { isZeroDecimalCurrency } from '@/src/common/zustand/utils';
import {
  CURRENCY_CODE,
  CURRENCY_SYMBOL,
} from '@/src/components/Checkout/Checkout.constants';
import { getStripePaymentIntegrationId } from '../TaxService/helpers';
import { handleRequest } from '@/src/services/helpers';
import { getPageSlug } from '@/src/components/Checkout/Checkout.utils';
import { isCouponApplicable } from '@/src/common/components/OrderSummary/utils';
import { IDiscountData } from '@/src/components/Checkout/Checkout.types';
import { getSelectedPaymentOption } from '../PurchaseService/helpers';

type Frequency =
  | 'daily'
  | 'weekly'
  | 'every 2 weeks'
  | 'monthly'
  | 'every 6 months'
  | 'annual';

interface ExtraInvoiceItem extends PreviewItem {
  frequency: Frequency;
  type: 'extra_invoice';
}

interface SubscriptionItem extends PreviewItem {
  type: 'subscription';
}

interface PreviewItem {
  id?: string;
  product: StripeProduct;
  amount: number;
  reference: string;
  currency: (typeof CURRENCY_CODE)[keyof typeof CURRENCY_CODE];
}

let proxyBase = '/api/psproxy';
// Required for Jest tests - otherwise we see "only absolute urls are supported" error from fetch
if (process.env.CI_TEST) {
  proxyBase = 'http://localhost:3000/api/psproxy';
}

type PreviewResponse = {
  upcoming_invoice: {
    automatic_tax: {
      enabled: boolean;
      status: string;
    };
    customer_address: {
      city: string;
      country: string;
      state: string;
      postal_code: string;
      line1: string;
    };
    lines: {
      data: {
        amount: number;
        price: { id: string };
        discount_amounts: { amount: number }[];
        tax_amounts: { amount: number }[];
      }[];
    };
    total_discount_amounts: { amount: number }[];
    total_tax_amounts: { amount: number }[];
  };
  subscription_items: { price: string; reference: string }[];
  extra_invoice_items: { price: string; reference: string }[];
};

// TODO: Add response type from Go PS OA spec, using temporary manually created type - PreviewResponse
const getPreview = async (
  isTaxAvailable: boolean,
  isAddressStateValid: boolean
): Promise<PreviewResponse | null> => {
  const paymentPage = useOfferStore.getState().offer;
  const accSlug = paymentPage?.account.slug;
  const discount = usePageStore.getState().discount;
  const bumpSelected = usePageStore.getState().bumpSelected;
  const currency = paymentPage?.currency.code ?? CURRENCY_CODE.USD;
  const currencySymbol = paymentPage?.currency.symbol ?? CURRENCY_SYMBOL.USD;
  const discountCode = usePageStore.getState().discountCode;

  const zeroDecimal = isZeroDecimalCurrency(currencySymbol);

  const paymentOption = getSelectedPaymentOption();

  const live_mode = paymentPage?.status === 'live';
  const main = paymentPage?.product;
  const bump = paymentPage?.bumps[0];

  const mainAmount = paymentOption?.unit_amount;
  const bumpAmount = paymentPage?.bumps[0]?.payment_option?.unit_amount;
  const trialAmount = paymentOption?.trial_period_price
    ? Number(paymentOption?.trial_period_price) * (zeroDecimal ? 1 : 100)
    : 0;
  const address = usePageStore.getState().addressValues;

  const isPaidTrial = Number(paymentOption?.trial_period_price) > 0;

  let items: PreviewItem[] = [];

  if (!paymentPage?.product) {
    return null;
  }

  items.push({
    id: paymentOption?.stripe_price_id,
    type: 'subscription',
    amount: mainAmount!,
    frequency: paymentOption?.frequency as Frequency,
    currency,
    product: {
      id: paymentPage.product.stripe_id ?? '',
      name: paymentPage.product.name,
      tax_code: paymentPage.product.product_tax_code,
    },
    reference: `main_product`,
  } as SubscriptionItem);

  if (main && isPaidTrial) {
    items.push({
      type: 'extra_invoice',
      amount: trialAmount,
      currency,
      product: {
        id: paymentPage?.product?.stripe_id ?? '',
        name: paymentPage.product.name,
        tax_code: paymentPage.product.product_tax_code,
      },
      reference: `paid_trial`,
    } as ExtraInvoiceItem);
  }

  if (bumpSelected && bump && bump.product) {
    items.push({
      type: 'extra_invoice',
      amount: bumpAmount!,
      currency,
      product: {
        id: paymentPage?.bumps[0]?.product?.stripe_id ?? '',
        name: paymentPage?.bumps[0]?.product?.name,
        tax_code: paymentPage?.bumps[0]?.product?.product_tax_code,
      },
      reference: `bump_offer`,
    } as ExtraInvoiceItem);
  }

  const getPreviewDiscount = (discount: IDiscountData | null) => {
    if (
      discount?.discount &&
      isCouponApplicable(discount, mainAmount!, zeroDecimal, currency)
    ) {
      return {
        ...discount,
        discount: Number(discount.discount) ?? 0,
        code: discountCode,
      };
    }

    return null;
  };

  const payload = {
    account: accSlug,
    integration_id: getStripePaymentIntegrationId(),
    live_mode,
    items,
    discount: getPreviewDiscount(discount),
    upcoming_invoice_params: {
      customer_details: {
        address: isAddressStateValid
          ? {
              city: address?.city,
              country: address?.country,
              state: address?.state,
              postal_code: address?.postal_code,
              line1: address?.line1,
            }
          : {
              city: null,
              country: null,
              state: null,
              postal_code: null,
              line1: null,
            },
      },
      automatic_tax: {
        enabled: isTaxAvailable,
      },
      coupon: usePageStore.getState().discountCode,
      ...((isPaidTrial || bumpSelected) && {
        trial_days: paymentOption?.trial_days ?? 0,
      }),
    },
  };

  return await getSubscriptionPreviewApi(payload);
};

export const getSubscriptionPreviewApi = async (payload: object) =>
  await handleRequest(
    `${proxyBase}/${getPageSlug()}/stripe/subscriptions/preview`,
    'POST',
    payload
  )
    .then(handleResponse(true))
    .then((data) => data)
    .catch(logError);

const handleResponse =
  (json: boolean = true) =>
  (response) => {
    if (!response.ok) {
      throw new Error(`Network response was not OK.`);
    }
    return json ? response.json() : response;
  };

const logError = (error) => {
  console.error('There has been a problem with your fetch operation:', error);
};

export default getPreview;
