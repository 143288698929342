import { useOfferStore, usePageStore } from '@/src/common/zustand';
import { DeadlineType, DeadlineUnit } from '../Offer/Offer.enums';
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import {
  calculateSecondsByDate,
  calculateTripwireSeconds,
  getTripwireStartDate,
} from './Deadline.helpers';
import { useRouter } from 'next/router';
import { addSeconds } from 'date-fns';

const useDeadlineProps = (): {
  startingTimeLeft: number;
  deadlineDate: Date;
} | null => {
  const router = useRouter();
  const { slug } = router.query;
  const deadline = useOfferStore((state) => state.offer?.deadline);
  const deadlineInfo = usePageStore((state) => state.deadlineInfo);

  const isTypeEnabled = () => {
    switch (deadline?.main_offer?.type) {
      case DeadlineType.Evergreen:
      case DeadlineType.OptinToOffer:
        return deadlineInfo?.deadlineUtc;
      case DeadlineType.Fixed:
        return deadline?.main_offer?.expiry_date;
      case DeadlineType.Tripwire:
        return deadline?.main_offer?.length;
      default:
        return false;
    }
  };

  if (!deadline?.main_offer?.enabled || !isTypeEnabled()) {
    return null;
  }

  const getStartingTimeLeft = () => {
    switch (deadline?.main_offer?.type) {
      case DeadlineType.Evergreen:
      case DeadlineType.OptinToOffer:
        return calculateSecondsByDate(new Date(deadlineInfo?.deadlineUtc!));
      case DeadlineType.Fixed:
        // Extract the deadline and timezone from the main_offer object
        const timezone = deadline.main_offer.timezone!;

        // Convert the deadline to UTC. This is necessary because the deadline is in a specific timezone,
        // and we need to convert it to a universal standard (UTC) to avoid discrepancies.
        // The timeZone option is used to specify the timezone of the deadline.
        // If we don't specify this, the date will be converted to the visitor's timezone twice, which is not what we want.
        // For example, if the deadline is 2024-04-01 23:59:59 in EDT, and we omit the timeZone option,
        // the baseDate would be 2024-04-02 07:59:59 in CEST. But if we use the timeZone option,
        // the baseDate is correctly converted to 2024-04-02 05:59:59.
        // However, this date is still in the visitor's timezone, so we need to convert it one more time.
        const baseDate = zonedTimeToUtc(
          deadline.main_offer.expiry_date!,
          timezone,
          {
            timeZone: 'UTC',
          }
        );

        // Convert the baseDate to UTC based on the visitor's timezone offset.
        // This ensures that the date is in UTC regardless of the visitor's location.
        const utcDate = new Date(
          baseDate.getTime() + baseDate.getTimezoneOffset() * 60 * 1000
        );

        // Format the UTC date back to the original input format.
        // This is done so that we can use the same method for evergreen deadlines.
        const utcFormat = format(utcDate, 'yyyy-MM-dd HH:mm:ss');

        // Calculate the time left until the deadline.
        // This is done by subtracting the current date from the deadline date.
        return calculateSecondsByDate(new Date(utcFormat));
      case DeadlineType.Tripwire:
        const timeUnit = deadline?.main_offer?.unit ?? DeadlineUnit.Minutes;
        const deadlineLength = deadline?.main_offer?.length ?? 0;

        const deadlineTime = calculateTripwireSeconds(
          deadlineLength,
          timeUnit,
          getTripwireStartDate(slug as string)
        );

        return deadlineTime;
      default:
        return null;
    }
  };

  const startingTimeLeft = getStartingTimeLeft();

  const getDeadlineDate = () => {
    switch (deadline?.main_offer?.type) {
      case DeadlineType.Evergreen:
      case DeadlineType.OptinToOffer:
        const isoDeadline = new Date(
          `${deadlineInfo?.deadlineUtc?.replace(' ', 'T')}Z`
        );

        const deadlineDate = utcToZonedTime(
          isoDeadline,
          deadline.main_offer.timezone!
        );

        return deadlineDate;
      case DeadlineType.Fixed:
        return new Date(deadline.main_offer.expiry_date!);
      case DeadlineType.Tripwire:
        return addSeconds(Date.now(), startingTimeLeft!);
      default:
        return null;
    }
  };

  const deadlineDate = getDeadlineDate();

  if (!startingTimeLeft || !deadlineDate) {
    return null;
  }

  return { startingTimeLeft, deadlineDate };
};

export default useDeadlineProps;
