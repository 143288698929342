import { FC } from 'react';

const SelectedSvg: FC<{ color: string }> = ({ color }): JSX.Element => {
  return (
    <svg
      width="70"
      height="57"
      viewBox="0 0 70 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="rounded-tr-md relative -top-20 -mt-[0.9rem] float-right"
    >
      <path
        d="M72 6V58L37 29.5L2 1H67C69.7614 1 72 3.23858 72 6Z"
        fill={color}
        stroke={color}
      />
      <path
        d="M47 19.9474L51.3333 24L61 13"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default SelectedSvg;
