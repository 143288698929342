import { useCallback, useEffect, useState } from 'react';

const useMedia = (width: number = 700) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const updateTarget = useCallback(
    (e: MediaQueryListEvent | MediaQueryList) => {
      setIsMobile(e.matches);
    },
    []
  );

  useEffect(() => {
    const media = window.matchMedia(`(max-width: ${width}px)`);
    updateTarget(media);
    media.addEventListener('change', updateTarget);
    return () => media.removeEventListener('change', updateTarget);
  }, []);

  return isMobile;
};

export default useMedia;
