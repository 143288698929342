import {
  getCleanEmail,
  isEmailValid,
} from '@/src/components/Deadline/Deadline.utils';
import { EvergreenDeadline } from '../../pages/api/deadline/email';

const getDeadlineByEmailDdb = async (
  offerId: number,
  email: string
): Promise<EvergreenDeadline | null> => {
  // Validate email address below
  if (!email) {
    return {
      error: true,
      message: 'Email address is required.',
    };
  }

  // Replace empty spaces with + sign
  email = email.replace(/\s/g, '+');

  if (!isEmailValid(email)) {
    return {
      error: true,
      message: 'Email address is invalid.',
    };
  }

  const cleanEmail = getCleanEmail(email);

  return fetch('/api/deadline/email', {
    method: 'POST',
    body: JSON.stringify({
      offerId,
      email: cleanEmail,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch(() => {
      // console.log('getDeadlineByEmail', err);
      return {
        error: true,
        message: 'Something went wrong. Please try again later.',
      };
    });
};

export default getDeadlineByEmailDdb;
