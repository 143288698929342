import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';

import { Checkbox } from './Checkbox';
import { usePageStore } from '@/src/common/zustand';
import { PageStore } from '../../zustand/types';
import { components } from '@/src/schemas/offer';
import { GDPR } from '@/src/components/Checkout/Checkout.constants';

interface IProps {
  termsEnabled: boolean;
  termsText?: string | null;
  gdprEnabled: components['schemas']['CheckoutSchema']['gdpr']['visibility'];
  gdprText?: string | null;
}

export const TermsAndConditions: FC<IProps> = ({
  termsEnabled,
  termsText,
  gdprEnabled,
  gdprText,
}) => {
  const inEU = usePageStore.getState().inEU;
  const { t } = useTranslation('paymentPage');

  const displayGdpr =
    (gdprEnabled === GDPR.EU && inEU) || gdprEnabled === GDPR.ALL
      ? true
      : false;

  const termsAndConditionsValues = usePageStore(
    (state) => state.termsAndConditionsValues
  );

  const {
    register,
    formState,
    formState: { errors, isValid },
    trigger,
    watch,
    reset,
  } = useForm({
    mode: 'all',
    defaultValues: termsAndConditionsValues,
  });

  const updateTermsAndConditionsState = usePageStore(
    (state) => state.updateTermsAndConditionsState
  );

  const updateTermsAndConditionsValues = usePageStore(
    (state) => state.updateTermsAndConditionsValues
  );

  useEffect(() => {
    updateTermsAndConditionsState(formState);
  }, [isValid]);

  useEffect(() => {
    const subscription = watch((value) => {
      updateTermsAndConditionsValues(value);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    reset(); // https://github.com/react-hook-form/react-hook-form/issues/2492#issuecomment-904234100

    const unsubscribe = usePageStore.subscribe(
      (state) => (state as PageStore).triggerFormValidation,
      (triggerFormValidation) => {
        if (triggerFormValidation) {
          trigger();
        }
      }
    );
    return () => unsubscribe();
  }, []);

  return termsEnabled || displayGdpr ? (
    <div className="w-full mt-6 lg:mt-8">
      <h2 className="text-xl sm:text-2xl">{t('termsConditions')}</h2>
      {termsEnabled && (
        <Checkbox
          id="terms"
          name="terms"
          register={register}
          registerOptions={{ required: true }}
          errors={errors['terms'] ?? null}
          errorName={t('termsAndConditions')}
          labelText={termsText ?? t('agreeToTermsConditions')}
        />
      )}

      {displayGdpr && (
        <Checkbox
          id="gdpr"
          name="gdpr"
          errors={null}
          register={register}
          registerOptions={{}}
          errorName={t('gdpr')}
          labelText={gdprText ?? t('likeToReceiveMessage')}
        />
      )}
    </div>
  ) : (
    <></>
  );
};
