import { useTranslation } from 'next-i18next';
import { Offer } from '@/src/pages/[host]/[uid]/[slug]';
import { memo } from 'react';

const Footer = ({
  footerLinks,
}: {
  footerLinks: Offer['customization']['footer_links'] | null;
}) => {
  const { t } = useTranslation('paymentPage');

  if (!footerLinks?.length) {
    return null;
  }

  return (
    <div className="text-gray-400 bg-voma-background-color text-sm my-6">
      <div className="leading-relaxed flex flex-wrap justify-center">
        {footerLinks!.map((link, index) => (
          <span
            key={index}
            className="mb-2 mx-4 lg:mr-0 lg:border-l-2 lg:first:border-none lg:first:pl-0 lg:pl-4 text-center"
          >
            <a
              href={link.url}
              className="hover:underline"
              target={'_blank'}
              rel="noreferrer"
            >
              {link.type || link.url}
            </a>
          </span>
        ))}
      </div>
      <div className="text-center lg:mt-1">
        {t('poweredBy')}&nbsp;
        <a
          href={'http://www.vomahq.com/'}
          target="_blank"
          rel="noreferrer"
          className="hover:underline"
        >
          Voma
        </a>
      </div>
    </div>
  );
};

export default memo(Footer);
