const ArrowUpIcon = ({ className = '' }) => (
  <svg
    width="12"
    height="6"
    viewBox="0 0 12 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      id="Accordion"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.52096 0.192L11.7844 4.864C12.0719 5.136 12.0719 5.552 11.7844 5.808C11.497 6.064 11.012 6.064 10.7246 5.808L6 1.6L1.27545 5.808C0.97006 6.064 0.502994 6.064 0.215569 5.808C-0.0718563 5.552 -0.0718563 5.136 0.215569 4.864L5.46108 0.192C5.76647 -0.0640001 6.23353 -0.0640001 6.52096 0.192Z"
      fill="#858585"
    />
  </svg>
);

export default ArrowUpIcon;
