import { FC } from 'react';
import { Timer } from './Timer';
import { useOfferStore, usePageStore } from '@/src/common/zustand';
import useDeadlineProps from './useDeadlineProps.hook';
import { DeadlineType, DeadlineUnit } from '../Offer/Offer.enums';
import useDeadlineTime from './useDeadlineTime.hook';
import { parse, isBefore } from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';

interface DeadlineProps {
  previewMode?: boolean;
}

const Deadline: FC<DeadlineProps> = ({
  previewMode = false,
}: DeadlineProps): JSX.Element | null => {
  const deadlineProps = useDeadlineProps();
  const deadlineTime = useDeadlineTime(deadlineProps?.startingTimeLeft);
  const deadline = useOfferStore((state) => state.offer?.deadline);
  const deadlineInfo = usePageStore((state) => state.deadlineInfo);

  const applyOfferStackDiscount = usePageStore(
    (state) => state.applyOfferStackDiscount
  );
  const setDeadlineExpired = usePageStore((state) => state.setDeadlineExpired);

  const onExpired = () => {
    if (previewMode) {
      return;
    }

    setDeadlineExpired(true);
  };

  if (
    deadline &&
    deadline?.main_offer?.type === DeadlineType.Fixed &&
    isPastDate(
      deadline?.main_offer?.expiry_date,
      deadline?.main_offer?.timezone
    )
  ) {
    onExpired();
    return null;
  }

  if (!deadlineTime) {
    return null;
  }

  if (
    deadlineInfo?.expired ||
    (deadlineTime && deadlineTime?.totalSeconds <= 0)
  ) {
    onExpired();
    return null;
  }

  const deadlineDate = deadlineProps?.deadlineDate ?? new Date();

  const title = deadline?.main_offer?.title ?? 'Your Special Offer';

  const description =
    deadline?.main_offer?.description ??
    'Hurry, this offer goes away after the deadline!';

  applyOfferStackDiscount();

  return (
    <Timer>
      <div
        className={`flex flex-col w-[303px] lg:w-[290px] justify-center ${
          description ? 'space-y-[15px] lg:space-y-[19px]' : ''
        }`}
      >
        <Timer.Title title={title} />
        <Timer.Description description={description} />
      </div>
      <div className="flex flex-col lg:mt-5 mt-[15px]">
        <div className="flex space-x-[9px] lg:space-x-4 flex-row">
          {Object.values(DeadlineUnit).map((unit) => (
            <div
              key={unit}
              className={
                unit === 'days' && deadlineTime[unit] <= 0 ? 'lg:hidden' : ''
              }
            >
              <Timer.TimeTile
                key={unit}
                number={deadlineTime[unit]}
                label={unit}
              />
            </div>
          ))}
        </div>
        <Timer.DeadlineText deadlineDate={deadlineDate} />
      </div>
    </Timer>
  );
};

export default Deadline;

function isPastDate(
  dateString: string | undefined,
  timeZone: string | undefined
) {
  if (!dateString || !timeZone) {
    return false;
  }
  // Parse the date string assuming it is in the local time zone
  const inputDate = parse(dateString, 'yyyy-MM-dd HH:mm:ss', new Date());

  // Convert the input date to UTC
  const inputDateUtc = zonedTimeToUtc(inputDate, timeZone);

  // Get the current date and time in the specified time zone
  const currentDateInTimeZone = utcToZonedTime(new Date(), timeZone);
  // Compare the input date (converted to UTC) with the current date in the specified time zone
  return isBefore(inputDateUtc, currentDateInTimeZone);
}
