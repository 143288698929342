import { useOfferStore } from '@/src/common/zustand';
import { useTranslation } from 'next-i18next';
import { useContext } from 'react';
import { PaymentOptionContext } from './PaymentOption';
import useCurrencyCodeStore from '@/src/common/zustand/useCurrencyCodeStore';

const Price = (): JSX.Element => {
  const { t } = useTranslation('prices');
  const currency = useOfferStore((state) => state.offer?.currency.symbol);
  const { currencyCode } = useCurrencyCodeStore();


  const { option } = useContext(PaymentOptionContext);

  const { price, rebills, frequency } = option!;

  const showFrequency = !rebills || rebills === 0;

  const periodAbr = !!frequency ? t(`pricePeriod.${frequency}`) : '';

  return (
    <p className="text-lg font-bold">
      {currencyCode}
      {currency}
      {price}
      {showFrequency && <span className="text-sm">{periodAbr}</span>}
    </p>
  );
};

export default Price;
