import { FC, ReactNode } from 'react';
import TimeTile from './TimeTile';
import Title from './Title';
import Description from './Description';
import DeadlineText from './DeadlineText';
import { useContrastStore } from '@/src/common/zustand/useContrastStore';
import { useOfferStore } from '@/src/common/zustand';

type SubComponents = {
  Title: typeof Title;
  Description: typeof Description;
  TimeTile: typeof TimeTile;
  DeadlineText: typeof DeadlineText;
};

const Timer: FC<{ children: ReactNode }> & SubComponents = ({
  children,
}): JSX.Element | null => {
  const { contrastColor } = useContrastStore();
  const deadline = useOfferStore((state) => state.offer?.deadline);
  const gradientBackground = deadline?.main_offer?.gradient_background ?? true;

  return (
    <div
      className={`
      flex
      flex-col
      sm:flex-row
      items-center
      justify-between
      md:mt-10
      md:mb-10
      mt-4
      mb-6
      border
      border-solid
      border-voma-timer-color
      rounded-[5px]
      p-[21px]
      lg:pl-[27px]
      lg:pr-[28px]
      lg:pt-[33px]
      lg:pb-[33px]
      w-full
      lg:w-[688px]
      lg:h-[165px]
      h-auto
      lg:space-x-6
      cursor-pointer
      ${
        gradientBackground
          ? 'bg-gradient-to-r from-accent-color to-accent-color-darker'
          : 'bg-accent-color'
      }
      ${contrastColor === 'white' ? 'text-white' : 'text-gray-800'}
    `}
    >
      {children}
    </div>
  );
};

Timer.Title = Title;
Timer.Description = Description;
Timer.TimeTile = TimeTile;
Timer.DeadlineText = DeadlineText;

export default Timer;
