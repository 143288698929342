import { useEffect, useState } from 'react';
import { PricesStorePayload } from '../zustand/usePricesStore';
import { useOfferStore } from '../zustand';
import { isZeroDecimalCurrency } from '../components/OrderSummary/utils';
import { CURRENCY_SYMBOL } from '@/src/components/Checkout/Checkout.constants';
import { PaymentType } from '@/src/components/Checkout/Checkout.enums';

export const defaultValues: PricesStorePayload = {
  mainPrices: {
    otp: 0,
    subscription: {
      amount: 0,
      trialAmount: 0,
    },
  },
  bumpPrice: 0,
  upsellPrices: {
    otp: 0,
    subscription: {
      amount: 0,
      trialAmount: 0,
    },
  },
};

const useAmounts = (): PricesStorePayload => {
  const [amounts, setAmounts] = useState<PricesStorePayload>(defaultValues);

  const paymentPage = useOfferStore((state) => state.offer);
  const currencySymbol = paymentPage?.currency.symbol ?? CURRENCY_SYMBOL.USD;
  const zeroDecimal = isZeroDecimalCurrency(currencySymbol);

  useEffect(() => {
    if (!paymentPage) {
      return;
    }

    const {
      payment_options: [{ payment_type, unit_amount, trial_period_price }],
      bumps: [bump],
      upsells,
    } = paymentPage;

    setAmounts({
      mainPrices: {
        otp: payment_type === PaymentType.one_time_fee ? unit_amount ?? 0 : 0,
        subscription: {
          amount:
            payment_type === PaymentType.subscription ? unit_amount ?? 0 : 0,
          trialAmount: trial_period_price
            ? Number(trial_period_price) * (zeroDecimal ? 1 : 100)
            : 0,
        },
      },
      bumpPrice: bump?.payment_option?.unit_amount ?? 0,
      upsellPrices: {
        otp:
          upsells?.[0]?.payment_option?.payment_type ===
          PaymentType.one_time_fee
            ? upsells[0].payment_option.unit_amount
            : 0,
        subscription: {
          amount:
            upsells?.[0]?.payment_option?.payment_type ===
            PaymentType.subscription
              ? upsells[0].payment_option.unit_amount!
              : 0,
          trialAmount: upsells?.[0]?.payment_option?.trial_period_price
            ? Number(upsells[0].payment_option.trial_period_price) *
              (zeroDecimal ? 1 : 100)
            : 0,
        },
      },
    });
  }, [paymentPage]);

  return amounts;
};

export default useAmounts;
