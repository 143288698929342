const ROUTES = require('./routes');

const removeQueryParams = ['fromUpsell', 'upsellId'];

module.exports = {
  extractParamFromUrl: {
    account_id: (url) => {
      const result = url.match(/\/\/([^.^/]+)\./i);
      return result ? result[1] : null;
    },
    payment_page_id: (url) => {
      const result = url.match(/\/\/[^\/]*\/([^\/]+)/i);
      return result ? result[1] : null;
    },
    payment_page: (pathname) => {
      const segments = pathname.split('/');
      const count =
        segments.length - 1 - (pathname[pathname.length - 1] == '/' ? 1 : 0);
      return count > 1
        ? {
            accountUid: segments[1],
            paymentPageId: segments[2],
          }
        : {};
    },
    order_hash: (pathname) => {
      const result = pathname.match(/\/orders\/([^/]+)/i);
      return result ? result[1] : null;
    },
    upsell_order_hash: (url) => {
      const result = url.match(/upsellOrder\/(\w+)/i);
      return result ? result[1] : null;
    },
    upsell_id: (url) => {
      const result = url.match(/\/(\w+)\/(?:yes|no)$/i);
      return result ? result[1] : null;
    },
    should_buy_upsell: (href) => {
      const result = href.match(/\/(yes|no)$/i);
      return result ? result[1] === 'yes' : false;
    },
  },
  getRouteByUrl: (url) => {
    if (url.match('/logger')) {
      return ROUTES.LOGGER;
    }
    if (url.match('/orders')) {
      return ROUTES.ORDERS;
    }
    if (url.match('/update/login')) {
      return ROUTES.SUBSCRIPTION_PORTAL;
    }
    if (url.match(/^\/(_next|static)/i) || url.match(/favicon.ico/i)) {
      return ROUTES.STATIC;
    }
    if (url.match('/api/')) {
      return ROUTES.API;
    }
    if (url.match('/health-check')) {
      return ROUTES.HEALTH_CHECK;
    }
    return ROUTES.PAYMENT_PAGE;
  },

  // Replace customization with the one we got from a POST request which was made from the admin site by clicking "Preview" button
  prepareResponseForPreview: (response, checkout_template_customization) => ({
    ...response,
    payment_page: {
      ...response.payment_page,
      checkout_template_customization,
    },
  }),
  clearQueryString: () => {
    const queryStringFiltered = getQueryStringFiltered();
    window.history.replaceState(
      null,
      null,
      `${window.location.pathname}${queryStringFiltered}${
        window.location.hash ? `${window.location.hash}` : ''
      }`
    );
  },
  clearUrlHash: () => {
    const queryStringFiltered = getQueryStringFiltered();
    window.history.replaceState(
      null,
      null,
      `${window.location.pathname}${queryStringFiltered}`
    );
  },
};

function getQueryStringFiltered() {
  const queryString = window.location.search.substring(1);
  const params = new URLSearchParams(queryString);
  const obj = {};

  // iterate over all keys
  for (const key of params.keys()) {
    if (removeQueryParams.indexOf(key) >= 0) continue;

    if (params.getAll(key).length > 1) {
      obj[key] = params.getAll(key);
    } else {
      obj[key] = params.get(key);
    }
  }
  return Object.keys(obj).length
    ? '?' + new URLSearchParams(obj).toString()
    : '';
}
