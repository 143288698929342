const TestModeSvg = (props) => (
  <svg width={16} height={24} viewBox="0 0 24 32" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.25.487A1.395 1.395 0 0 1 17.292 0c.426-.012.834.157 1.095.453.26.295.344.683.224 1.041l-3.93 11.339h7.986c.5 0 .958.244 1.186.634.228.389.188.857-.103 1.213L7.306 34.514c-.385.473-1.108.627-1.698.361-.59-.265-.866-.87-.65-1.419l4.306-11.29h-7.93c-.5 0-.959-.244-1.187-.633A1.046 1.046 0 0 1 .25 20.32l16-19.833zM11.61 13.66l2.056-5.943-9.764 12.116h7.208c.428.001.829.181 1.079.484s.319.693.185 1.048l-2.152 5.638 9.819-11.836h-7.153c-.423 0-.82-.175-1.073-.472a1.05 1.05 0 0 1-.204-1.035z"
      fill="#fff"
    />
  </svg>
);

export default TestModeSvg;
