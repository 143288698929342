import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';

const Sidebar = dynamic(() => import('@/components/Sidebar'));

import { usePageStore } from '@/src/common/zustand';

import { LayoutProps } from './Layout.types';
import NextButton from '../NextButton';
import { LayoutStep } from './Layout.enums';
import classNames from 'classnames';
// Preventing hydration mismatch error
// TODO: See if there is a better way to handle this
const PaymentOptionsList = dynamic(
  () => import('../PaymentOptions/PaymentOptionsList'),
  { ssr: false }
);

export const DesktopTwoStep = (props: LayoutProps) => {
  const { t } = useTranslation('paymentPage');
  const [step, setStep] = useState<LayoutStep>(LayoutStep.StepOne);

  useEffect(() => {
    !props.previewMode && window.scrollTo(0, 0);
  }, [step]);

  // Zustand store
  const contactInfoValid =
    usePageStore((state) => state.contactInformation?.isValid) || false;

  const setTriggerFormValidation = usePageStore(
    (state) => state.setTriggerFormValidation
  );

  const onNextButtonSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!contactInfoValid) {
      setTriggerFormValidation(true);
      return;
    }

    setStep(LayoutStep.StepTwo);
  };

  return (
    <div
      className={`flex justify-center pl-10 ${
        props.mirrored ? 'flex-row-reverse' : ''
      }`}
    >
      {props.children}
      <div className="sm:w-[429px] w-[374px] flex-none mt-9">
        {props.orderSummary}
        <div className={classNames(props.bonuses && 'my-[52px]')}>
          {props.bonuses}
        </div>
        {props?.sidebar && <Sidebar {...props.sidebar} />}
      </div>
      <div
        className={`${
          props.mirrored ? 'xl:mr-20' : 'xl:ml-20'
        } max-w-[46rem] flex-1`}
      >
        <div>
          <div
            className={`border-t-4 border-accent-color px-4 lg:px-7 2xl:px-10 py-1 pb-[40px] mt-9 bg-white`}
            data-testid="main-box"
          >
            <div
              className={step === LayoutStep.StepOne ? 'display' : 'hidden'}
              data-testid="contact-form"
            >
              {props.deadline}
              {props.intro}
              <PaymentOptionsList />
              <form onSubmit={onNextButtonSubmit}>
                {props.contactInformation}
                <NextButton text={t('pageNext')} />
              </form>
              {props.textBelowBuyButton}
            </div>
            <div
              className={step === LayoutStep.StepTwo ? 'display' : 'hidden'}
              data-testid="payment-form"
            >
              {props.orderBump}
              {props.termsAndConditions}
              {step === LayoutStep.StepTwo && props.checkoutForm}
              <button
                className="block mx-auto text-center mt-6 text-button-background-color hover:cursor-pointer hover:opacity-90"
                onClick={() => setStep(LayoutStep.StepOne)}
                data-testid="back-button"
              >
                &#8592; {t('pageReturn')}
              </button>
              {props.subscriptionNotices}
            </div>
          </div>
          {props.footer}
        </div>
      </div>
    </div>
  );
};
