import { sanitize } from 'isomorphic-dompurify';
import { Offer } from '@/src/pages/[host]/[uid]/[slug]';
import DOMPurify from 'dompurify';
import { memo } from 'react';

export const sanitizeOptions = {
  ADD_TAGS: ['iframe'],
  ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'target'],
  FORBID_ATTR: ['width', 'height'],
};

export const defineDOMPurifyHook = () => {
  DOMPurify?.addHook?.('uponSanitizeElement', (node: Element, data) => {
    if (data.tagName === 'iframe') {
      const src = node.getAttribute('src') || '';
      if (
        !src.startsWith('https://www.youtube.com/embed/') &&
        !src.includes('wistia.net/embed') &&
        !src.includes('loom.com/embed') &&
        !src.includes('vimeo.com')
      ) {
        return node.parentNode?.removeChild(node);
      }
    }
  });

  DOMPurify?.addHook?.('beforeSanitizeElements', (node: Element) => {
    if (node.tagName === 'IFRAME') {
      while (node.firstChild) {
        node.removeChild(node.firstChild);
      }
    }
  });
};

defineDOMPurifyHook();

export const TextBelowBuyButton = ({
  text,
  className = 'mt-6 lg:mt-8 mb-8',
}: {
  text: Offer['customization']['text_below_buy_button'];
  className?: string;
}) => (
  <div className={className}>
    {text && (
      <div
        className="headline-description fr-view mt-0"
        dangerouslySetInnerHTML={{
          __html: sanitize(text, sanitizeOptions),
        }}
      />
    )}
  </div>
);

export default memo(TextBelowBuyButton);
