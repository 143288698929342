import { useEffect } from 'react';
import { useTranslation } from 'next-i18next';

import { sanitize } from 'isomorphic-dompurify';

const Error = ({ message }) => (
  <p className="text-sm text-pink-600 pt-1 pl-8">{message}</p>
);

type CheckboxProps = {
  id: string;
  name: string;
  register: any;
  errors?: { type: string } | null;
  labelText: string;
  errorName: string;
  registerOptions?: any;
};

export const Checkbox = ({
  id,
  name,
  register,
  registerOptions,
  errors,
  errorName,
  labelText,
}: CheckboxProps) => {
  const { t } = useTranslation('common');

  useEffect(() => {
    let link: any = document.querySelector('.linkify-wrapper a');
    if (link) {
      link.setAttribute('target', '_blank');
      link.classList.add('text-button-background-color');
    }
  }, []);

  return (
    <>
      <div className="my-4 group">
        <label className="flex items-center">
          <input
            className="flex-none text-voma-accent-color w-6 h-6 mr-2 focus:ring-voma-accent-color-50 focus:ring-opacity-25 border border-gray-300 rounded"
            type="checkbox"
            {...register(id, registerOptions)}
            name={name}
            aria-invalid={errors?.type ? 'true' : 'false'}
          />
          <span className="flex-auto linkify-wrapper">
            <div dangerouslySetInnerHTML={{ __html: sanitize(labelText) }} />
          </span>
        </label>
        {errors?.type === 'required' && (
          <Error message={`${t('isRequired', { errorName })}`} />
        )}
      </div>
    </>
  );
};
