const CloseIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="11" cy="11" r="10" stroke="#2D2D2D" />
    <path
      d="M14.8438 7.15644C14.6355 6.94818 14.2978 6.94818 14.0895 7.15644L10.9998 10.2461L7.91006 7.15644C7.70169 6.94857 7.36433 6.94878 7.15622 7.15689C6.94811 7.36501 6.9479 7.70237 7.15576 7.91073L10.2455 11.0004L7.15576 14.0902C6.9479 14.2985 6.94811 14.6359 7.15622 14.844C7.36433 15.0521 7.70169 15.0523 7.91006 14.8444L10.9998 11.7547L14.0895 14.8444C14.2978 15.0523 14.6352 15.0521 14.8433 14.844C15.0514 14.6359 15.0516 14.2985 14.8438 14.0902L11.7541 11.0004L14.8438 7.91073C14.9438 7.81071 15 7.67504 15 7.53359C15 7.39213 14.9438 7.25646 14.8438 7.15644Z"
      fill="#2D2D2D"
      stroke="#2D2D2D"
      strokeWidth="0.4"
    />
  </svg>
);

export default CloseIcon;
