import { FC, memo } from 'react';
import { useTranslation } from 'next-i18next';
// import GoogleFontLoader from 'react-google-font';

type TimeTileProps = {
  number: number;
  label: string;
};

const TimeTile: FC<TimeTileProps> = ({
  number,
  label,
}: TimeTileProps): JSX.Element => {
  const { t } = useTranslation('paymentPage');
  const leadingZero = number < 10;

  return (
    <>
      {/* <GoogleFontLoader
        fonts={[
          {
            font: 'Lato',
            weights: [700],
          },
        ]}
      /> */}
      <div className="flex justify-center lg:px-2.5 py-2 lg:py-[13.5px] text-center w-[69px] lg:w-[71px] h-[65px] lg:h-[73px] border border-solid border-voma-timer-color text-voma-timer-color bg-white rounded-[5px]">
        <div className="flex flex-col justify-between">
          {/* <p className={`font-['Lato'] text-[42px] lg:text-[42px] leading-8 h-8`} */}
          <p className={`text-[42px] lg:text-[42px] leading-8 h-8`}>{`${
            leadingZero ? '0' : ''
          }${number}`}</p>
          <p className="uppercase voma-timer-label text-[10.5px] lg:text-[11px] leading-[7px] h-3 mt-[6px]">
            {t(label)}
          </p>
        </div>
      </div>
    </>
  );
};

export default memo(TimeTile);
