import { FC, useMemo } from 'react';
import {
  denominate,
  formatPrice,
  isSubscription,
  isZeroDecimalCurrency,
  withDiscount,
} from './utils';
import { useTranslation } from 'next-i18next';
import usePricesStore from '../../zustand/usePricesStore';
import { usePageStore } from '../../zustand';
import {
  CouponState,
  DiscountType,
} from '../../../modules/payment-page/models/elements/coupon';
import { components } from '@/src/schemas/offer';
import { DiscountCode } from './DiscountCode';
import classNames from 'classnames';

type ProductInfoProps = {
  product: string;
  option: components['schemas']['PaymentOption'];
  currency: components['schemas']['Currency']['symbol'];
  language: string;
  isBump?: boolean;
};

const ProductInfo: FC<ProductInfoProps> = ({
  product,
  option,
  currency,
  isBump,
}): JSX.Element => {
  const { t } = useTranslation(['paymentPage', 'prices']);
  const main = usePricesStore((state) => state.main);
  const discount = usePageStore((state) => state.discount);
  const priceDiscount = usePricesStore((state) => state.discount);
  const bumpSelected = usePageStore((state) => state.bumpSelected);

  const discountCode = usePageStore((state) => state.discountCode);

  const couponState = usePageStore((state) => state.couponState);

  const isSub = isSubscription(option.payment_type);
  const isFreeTrial =
    option.trial_period_price !== null &&
    typeof option.trial_period_price !== 'undefined' &&
    +option.trial_period_price === 0 &&
    Number(option.trial_days ?? 0) > 0;

  const isPaidTrial =
    option.trial_period_price &&
    +option.trial_period_price > 0 &&
    Number(option.trial_days ?? 0) > 0;

  const originalPrice = +(option.price ?? 0);

  const mainPrice = useMemo(() => {
    if (
      isBump ||
      !isSub ||
      (((bumpSelected && isFreeTrial) || isPaidTrial) && !discount?.recurring)
    ) {
      return originalPrice;
    }
    if (
      Number(discount?.discount) > 0 &&
      discount?.recurring &&
      discount?.discount_type === DiscountType.flat
    ) {
      return originalPrice - denominate(currency)(priceDiscount);
    }

    if (
      Number(discount?.discount) > 0 &&
      discount?.recurring &&
      discount?.discount_type === DiscountType.percentage
    ) {
      return withDiscount(
        discount,
        originalPrice,
        isZeroDecimalCurrency(currency)
      );
    }

    if (Number(discount?.discount) > 0 && !discount?.recurring) {
      if (discount?.discount_type === DiscountType.flat) {
        return originalPrice - denominate(currency)(priceDiscount);
      }
      return denominate(currency)(
        main.subscription.amount - main.subscription.tax
      );
    }

    return originalPrice;
  }, [
    discount,
    main.subscription.amount,
    main.subscription.tax,
    priceDiscount,
    isSub,
    isBump,
  ]);

  return (
    <div className="pb-2">
      <div className="font-semibold text-2xl pb-1">{product}</div>
      {option.rebills === 0 && isSub && (
        <>
          <div className="block md:flex items-center flex-wrap">
            <span className="text-base sm:text-lg sm:mt-1 md:text-xl md:flex">
              {originalPrice > mainPrice && discount?.recurring && (
                <>
                  <del className="text-[#858585] mr-2">
                    {currency}
                    {formatPrice(originalPrice)}
                    {isSub && (
                      <>
                        <span>
                          {t(`pricePeriod.${option.frequency}`, {
                            ns: 'prices',
                          })}
                        </span>
                      </>
                    )}
                  </del>{' '}
                </>
              )}
              <span
                className={classNames({
                  'line-through text-[#858585]':
                    !!discount && !discount?.recurring,
                })}
              >
                {`${currency}${
                  discount?.recurring
                    ? formatPrice(mainPrice)
                    : formatPrice(originalPrice)
                }`}
                {t(`pricePeriod.${option.frequency}`, { ns: 'prices' })}
              </span>
            </span>
            {!discount?.recurring &&
              discountCode &&
              couponState !== CouponState.inapplicable && (
                <div className="md:ml-2 ml-0 md:mt-2">
                  <DiscountCode />
                </div>
              )}
          </div>
        </>
      )}
      {/* {(option.rebills ?? 0) > 0 && isSub && (
        <span className="text-base sm:text-lg sm:mt-1 md:text-xl flex">
          {option.rebills} {t('paymentsOf', { ns: 'prices' })}&nbsp;
          {!!discount?.recurring && (
            <>
              <del className="text-[#858585]">
                {currency}
                {formatPrice(option.price ?? 0)}
              </del>{' '}
              &nbsp;
            </>
          )}
          {currency}
          {formatPrice(!!discount?.recurring ? mainPrice : option.price ?? 0)}
        </span>
      )} */}
      {(isBump || !isSub) && (
        <div className="md:flex align-middle items-center w-full max-w-full">
          <span className="text-base sm:text-lg sm:mt-1 md:text-xl md:flex md:pb-2 mr-2 md:mr-0">
            <span
              className={classNames({
                'line-through text-[#858585]': discountCode && !isBump,
              })}
            >{`${currency}${formatPrice(option.price ?? 0)} `}</span>{' '}
          </span>
          {!isBump &&
            discountCode &&
            couponState !== CouponState.inapplicable && (
              <DiscountCode
                className={classNames(
                  'md:ml-2 ml-0 overflow-hidden md:w-auto',
                  discountCode.length > 15 && 'w-full'
                )}
              />
            )}
        </div>
      )}
    </div>
  );
};

export default ProductInfo;
