import Image from 'next/image';
import Button from '../Checkout/CheckoutForm/Button';
import { PayPalFormProps } from '../PayPalForm/PayPalForm';

const FakeCheckoutForm = ({
  addressEnabled = false,
  forceMobile = false,
  billingAddress,
  paymentMethod,
  payPalElement = null,
  btnLabel,
}: {
  addressEnabled?: boolean;
  forceMobile?: boolean;
  billingAddress?: string;
  paymentMethod?: string;
  payPalElement?: React.ReactElement<PayPalFormProps> | null;
  btnLabel?: string;
}) => (
  <div className="w-full mt-6 lg:mt-8 max-w-full">
    {addressEnabled && (
      <>
        <h2 className="text-xl sm:text-2xl mb-5">{billingAddress}</h2>
        <div
          className="relative w-full h-full"
          style={{ minHeight: forceMobile ? '163px' : '326px' }}
        >
          <Image
            alt="Billing Address"
            loader={({ src }) => `/static/img/placeholder/${src}`}
            src="billing-address.png"
            fill={true}
            style={{ objectFit: 'contain' }}
          />
        </div>
      </>
    )}
    <h2 className="text-xl sm:text-2xl" data-testid="payment-element">
      {paymentMethod}
    </h2>
    {!!payPalElement && (
      <div className="w-60 my-4">
        <Image
            alt="Payment Element"
            loader={({ src }) => `/static/img/placeholder/${src}`}
            src="paypal.png"
            width={238}
            height={43}
            sizes="100vw"
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </div>
    )}
    {addressEnabled ? (
      <div
        className="relative w-full h-full"
        style={{ minHeight: forceMobile ? '62px' : '124px' }}
      >
        <Image
          alt="Payment Element"
          loader={({ src }) => `/static/img/placeholder/${src}`}
          src="payment-element.png"
          fill={true}
          style={{ objectFit: 'contain' }}
        />
      </div>
    ) : (
      <div
        className="relative w-full h-full"
        style={{ minHeight: forceMobile ? '113px' : '226px' }}
      >
        <Image
          alt="Payment Element"
          loader={({ src }) => `/static/img/placeholder/${src}`}
          src="payment-element-w-address.png"
          fill={true}
          style={{ objectFit: 'contain' }}
        />
      </div>
    )}
    <Button buyText={btnLabel!} />
  </div>
);

export default FakeCheckoutForm;
