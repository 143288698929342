import { useStripe, useElements } from '@stripe/react-stripe-js';

import { PayPalScriptProvider } from '@paypal/react-paypal-js';

import { usePageStore } from '@/src/common/zustand';

import { Offer } from '@/src/pages/[host]/[uid]/[slug]';
import { components } from '@/src/schemas/offer';
import { isFormValid } from '@/src/components/Checkout/Checkout.utils';
import { PAYMENT_TYPE } from '@/src/components/Checkout/Checkout.constants';
import { usePaymentOption } from '@/src/hooks';
import PayPalOptionsHandler from './PayPalOptionsHandler';

export type PayPalFormProps = {
  clientId: string;
  termsEnabled: boolean;
  gdprEnabled: components['schemas']['CheckoutSchema']['gdpr']['visibility'];
  addressEnabled: boolean;
  thankYouPageUrl: string;
  paymentPageId: number;
  paymentIntegrationId: number | null;
  paymentPage: Omit<Offer, 'account'>;
  integrations: Offer['account']['integrations'];
};

// TODO: Read data from the Zustand store
const PayPalForm = ({
  clientId,
  termsEnabled,
  gdprEnabled,
  addressEnabled,
  thankYouPageUrl,
  paymentIntegrationId,
  paymentPage,
  integrations,
}: PayPalFormProps): JSX.Element | null => {
  const stripe = useStripe();
  const elements = useElements();

  const isProcessingPayment = usePageStore(
    (state) => state.isProcessingPayment
  );

  const setPaymentElementPaymentError = usePageStore(
    (state) => state.setPaymentElementPaymentError
  );

  const setTriggerFormValidation = usePageStore(
    (state) => state.setTriggerFormValidation
  );

  const paymentOption = usePaymentOption();

  const getValid = async () => {
    if (!stripe || !elements) {
      return false;
    }

    setPaymentElementPaymentError(null);
    setTriggerFormValidation(false);

    let addressElement = elements?.getElement('address');

    if (addressElement) {
      const validateAddress = await addressElement.getValue();

      if (!validateAddress?.complete) {
        return false;
      }
    }

    if (!isProcessingPayment && isFormValid(addressEnabled, termsEnabled)) {
      return true;
    } else {
      return false;
    }
  };

  const onClick = async (_, actions) => {
    const isFormValid = await getValid();

    if (!isFormValid) {
      setPaymentElementPaymentError({
        error: 'Please fill out all required fields.',
      });
      setTriggerFormValidation(true);
      return actions.reject();
    }
  };

  return (
    <PayPalScriptProvider
      options={{
        'client-id': clientId,
        currency: paymentPage.currency.code,
        vault: paymentOption?.payment_type === PAYMENT_TYPE.subscription,
        intent:
          paymentOption?.payment_type === 'subscription'
            ? 'subscription'
            : 'capture',
      }}
    >
      <PayPalOptionsHandler
        onClick={onClick}
        gdprEnabled={gdprEnabled}
        thankYouPageUrl={thankYouPageUrl}
        paymentIntegrationId={paymentIntegrationId}
        paymentPage={paymentPage}
        integrations={integrations}
      />
    </PayPalScriptProvider>
  );
};

export default PayPalForm;
