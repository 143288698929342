import { FC } from 'react';
const TITLE_LIMIT = 44;

const Title: FC<{ title: string }> = ({ title }): JSX.Element => (
  <p
    className={`mb-[15px] lg:mb-0 tracking-[-0.36px] lg:tracking-tighter text-lg lg:text-[26px] leading-4 lg:leading-[26px] lg:max-w-[270px] break-words whitespace-nowrap overflow-hidden text-ellipsis lg:whitespace-normal`}
  >
    {title.slice(0, TITLE_LIMIT)}
  </p>
);

export default Title;
