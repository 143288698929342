import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import { PaymentType } from '../models/responsePaymentPage';
import { usePageStore } from '@/src/common/zustand';

// @ts-ignore
import subscriptionIcon from '../assets/img/email.png';
import { Offer } from '@/src/pages/[host]/[uid]/[slug]';
import { usePaymentOption } from '@/src/hooks';
import { useEffect, useState } from 'react';

type SubscriptionNoticesProps = {
  buy?: boolean;
  paymentPage: Omit<Offer, 'account'>;
};

export const SubscriptionNotices = ({
  buy,
  paymentPage,
}: SubscriptionNoticesProps): JSX.Element | null => {
  const [loaded, setLoaded] = useState<boolean>(false);

  // Force client side rendering to prevent hydration mismatch
  useEffect(() => {
    setLoaded(true);
  }, []);

  const bumpSelected = usePageStore((state) => state.bumpSelected);
  const { t } = useTranslation('paymentPage');

  const paymentOption = usePaymentOption();
  const bump = paymentPage.bumps[0];

  const isMainProductSubscription =
    paymentOption?.payment_type === PaymentType.subscription &&
    typeof paymentOption.rebills !== 'undefined' &&
    +paymentOption?.rebills === 0;

  if (!isMainProductSubscription || !loaded) {
    return null;
  }

  let subscriptionCount: number = 1; // Main product is subscription, so initial value is 1.
  let subscriptionText: string = '';

  if (
    bump &&
    bump.enabled &&
    bumpSelected &&
    bump.payment_option.payment_type === PaymentType.subscription
  )
    subscriptionCount++;
  if (subscriptionCount > 0) {
    subscriptionText = t('subscriptionText');
  }

  const paymentElements = [
    {
      ...paymentOption,
      productName: paymentPage?.product.name,
    },
  ];
  if (bump && bump.enabled && bumpSelected) {
    paymentElements.push({
      ...bump.payment_option,
      productName: bump.customization.form_preview.product_name,
    });
  }
  const subscriptionOptions: typeof paymentElements = [];

  paymentElements.map((element) => {
    if (element.payment_type === PaymentType.subscription) {
      subscriptionOptions.push(element);
    }
  });

  const freeTrialCount = subscriptionOptions.reduce((acc, option) => {
    if (
      +(option.trial_days ?? 0) > 0 &&
      +(option.trial_period_price ?? 0) === 0
    ) {
      return acc + 1;
    } else return acc;
  }, 0);

  return (
    <>
      {buy && subscriptionText && (
        <p className="text-sm mt-6 text-center">{subscriptionText}</p>
      )}
      {subscriptionOptions.length > 0 && freeTrialCount > 0 && (
        <div className="mt-6 text-sm bg-voma-gray-color px-4 py-4 flex rounded-md max-w-full mx-auto">
          <div className="relative w-[24px]">
            <Image
              alt="Subscription icon"
              src={subscriptionIcon}
              fill={true}
              style={{ objectFit: 'contain' }}
              className="object-left"
            />
          </div>
          <p className="ml-3 flex-auto">
            <b>{t('emailReminder')}&nbsp;&nbsp;</b>
            <span>{`- ${t('freeTrialDescription')}`}</span>
          </p>
        </div>
      )}
    </>
  );
};
