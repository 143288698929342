import { usePageStore } from '../../zustand';
import Image from 'next/image';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import CloseIcon from '../SVGIcons/CloseIcon';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

export const DiscountCode: FC<{ className?: string; variation?: 1 | 2 }> = ({
  className = '',
  variation = 1,
}) => {
  const discountCode = usePageStore((state) => state.discountCode);

  const setDiscountCode = usePageStore((state) => state.setDiscountCode);

  const setCouponState = usePageStore((state) => state.setCouponState);

  const setDiscount = usePageStore((state) => state.setDiscount);
  const { t } = useTranslation(['prices']);

  const setDiscountProducts = usePageStore(
    (state) => state.setDiscountProducts
  );

  const clearValue = () => {
    setDiscount(null);
    setDiscountCode(null);
    setDiscountProducts(null);
    setCouponState(null);
  };

  return (
    <div
      className={classNames(
        className,
        'bg-[#EAEAEA] flex items-center p-1 pr-0 gap-2.5 relative bottom-1 mt-2 md:mt-1',
        {
          'px-2 md:pt-0 pr-3 w-fit': variation === 1,
          'border-b-2 bg-white py-2 text-lg': variation === 2,
        }
      )}
    >
      <Image
        priority
        src="/static/assets/images/discount.svg"
        width={17}
        height={17}
        alt="Lesson icon"
        className="inline-block"
      />{' '}
      <span
        className={classNames(
          'uppercase align-text-bottom overflow-hidden whitespace-nowrap text-ellipsis',
          variation === 1 && 'flex-grow'
        )}
      >
        {discountCode}
      </span>
      {variation === 2 && (
        <span className="align-text-bottom lowercase overflow-hidden whitespace-nowrap text-ellipsis">
          {t('discountApplied')}!
        </span>
      )}
      <span
        className={classNames({ 'ml-auto': variation === 2 }, 'cursor-pointer')}
        onClick={clearValue}
        data-testid="coupon-button"
      >
        {variation === 2 ? (
          <CloseIcon />
        ) : (
          <FontAwesomeIcon icon={faX} className="text-[#858585]" size="xs" />
        )}
      </span>
    </div>
  );
};
