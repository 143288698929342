import { useContext } from 'react';
import { PaymentType } from '../../Checkout/Checkout.enums';
import SubscriptionIcon from './SubscriptionIcon';
import { PaymentOptionContext } from './PaymentOption';
import { useTranslation } from 'next-i18next';

const Type = (): JSX.Element => {
  const { t } = useTranslation();
  const { option, selected, color } = useContext(PaymentOptionContext);

  const { payment_type: type, rebills } = option!;

  const subscription = t('subscription', { ns: 'prices' });
  const singlePaymentOf = t('singlePaymentOf', { ns: 'paymentOptions' });
  const paymentsOf = t('paymentsOf', { ns: 'prices' });

  const getOptionType = () => {
    if (type === PaymentType.one_time_fee) {
      // Example: "1 Payment of"
      return `1 ${singlePaymentOf}`;
    }

    if (type === PaymentType.subscription && rebills && rebills > 0) {
      // Examples:
      // "3 Payments of"
      // "1 Payment of"
      return `${rebills} ${
        rebills && rebills > 1 ? paymentsOf : singlePaymentOf
      }`;
    }

    // Example: "Subscription"
    return subscription;
  };

  const optionType = getOptionType();

  return (
    <div className="flex">
      <p className={selected ? 'text-accent-color' : undefined}>{optionType}</p>
      {optionType === subscription && (
        <span className="ml-1 mt-0.5">
          <SubscriptionIcon color={selected ? color : '#000000'} />
        </span>
      )}
    </div>
  );
};

export default Type;
