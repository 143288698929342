import { useOfferStore, usePageStore } from '../common/zustand';

const usePaymentOption = () => {
  const selectedPaymentOptionId = usePageStore(
    (state) => state.selectedPaymentOptionId
  );

  const options = useOfferStore((state) => state.offer?.payment_options);

  if (!options) {
    return null;
  }

  return (
    options.find((option) => option.id === selectedPaymentOptionId) ||
    options.find((option) => !!option.default) ||
    options[0]
  );
};

export default usePaymentOption;
