import { useEffect, useState } from 'react';
import usePricesStore from '@/common/zustand/usePricesStore';
import { useOfferStore, usePageStore } from '@/common/zustand';
import { isZeroDecimalCurrency } from '@/src/common/components/OrderSummary/utils';
import { CURRENCY_SYMBOL } from '@/src/components/Checkout/Checkout.constants';

const getDiscount = (
  discount: number,
  amount: number,
  type: 'flat' | 'percentage',
  zeroDecimal: boolean
) => {
  if (amount === 0) {
    return 0;
  }

  if (discount) {
    if (type === 'flat') {
      return Number(discount) * (zeroDecimal ? 1 : 100);
    } else {
      return Math.round((amount * Number(discount)) / 100);
    }
  }
  return 0;
};

const useDiscount = (): number => {
  const [discountAmount, setDiscountAmount] = useState<number>(0);

  const currencySymbol = useOfferStore((state) => state.offer?.currency.symbol);
  const zeroDecimal = isZeroDecimalCurrency(
    currencySymbol ?? CURRENCY_SYMBOL.USD
  );

  const discount = usePageStore((state) => state.discount);
  const bumpSelected = usePageStore((state) => state.bumpSelected);
  const mainOtpAmount = usePricesStore((state) => state.main.otp.amount);
  const bumpAmount = usePricesStore((state) => state.bump.amount);

  useEffect(() => {
    const discountAmt = Number(discount?.discount);
    if (!discount || !discountAmt) {
      setDiscountAmount(0);
      return;
    }

    const discountedAmount = getDiscount(
      discountAmt,
      bumpSelected ? mainOtpAmount + bumpAmount : mainOtpAmount,
      discount.discount_type,
      zeroDecimal
    );

    setDiscountAmount(discountedAmount);
  }, [discount, bumpSelected]);

  return discountAmount;
};

export default useDiscount;
