import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { usePageStore } from '../../zustand';

import { Input } from './Input';

interface IProps {
  id: string;
  name: string;
  type: string;
  placeholder: string;
  register: any;
  registerOptions: any;
  autocomplete: string;
  errors?: any;
  errorName: any;
  disabled?: boolean;
  prefilled: boolean;
}

export const EmailField = (props: IProps) => {
  const router = useRouter();

  const { t } = useTranslation('common');

  const setDeadlineInfo = usePageStore((state) => state.setDeadlineInfo);

  const isPrefilled = props.prefilled;

  const onClickNotYou = () => {
    // Remove email address from Zustand
    setDeadlineInfo(null);

    // Remove vemail from URL
    const { query } = router;
    const params = new URLSearchParams(query as Record<string, string>);
    params.delete('vemail');

    // These are Next.js reserved query params that we don't want to include on refresh
    params.delete('host');
    params.delete('uid');
    params.delete('slug');

    // Refresh the page with the new URL
    const currentUrl = window.location.href.split('?')[0];
    const paramString = params.toString();

    const newUrl = `${currentUrl}${
      paramString.length > 0 ? `?${paramString}` : ''
    }`;

    window.location.replace(newUrl);
  };

  const NotYouLink = () => (
    <span
      onClick={() => onClickNotYou()}
      className="text-right z-10 absolute right-4 top-2 mt-2 text-sm text-gray-600 hover:text-gray-800 hover:cursor-pointer"
    >
      {t('notYou')}
    </span>
  );

  return (
    <div className="relative">
      {isPrefilled ? <NotYouLink /> : null}
      <Input {...props} />
    </div>
  );
};
