import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';

import verifyCoupon from '../../api/verifyCoupon';

import { CouponState } from '../../../modules/payment-page/models/elements/coupon';
import usePricesStore, { PricesStore } from '../../zustand/usePricesStore';
import { isCouponApplicable, isZeroDecimalCurrency } from './utils';
import { useOfferStore, usePageStore } from '../../zustand';
import { useDiscount } from '../../hooks';
import {
  CURRENCY_CODE,
  CURRENCY_SYMBOL,
} from '@/src/components/Checkout/Checkout.constants';
import { usePaymentOption } from '@/src/hooks';

interface IProps {
  accountSlug: string;
  paymentPageId: number;
  productId: number;
  bumpId: number;
  discountEnabled: boolean;
}

export const DiscountCodeInput = ({
  accountSlug,
  paymentPageId,
  productId,
  bumpId,
  discountEnabled,
}: IProps) => {
  const setDiscountCode = usePageStore((state) => state.setDiscountCode);

  const setDiscount = usePageStore((state) => state.setDiscount);

  const setDiscountProducts = usePageStore(
    (state) => state.setDiscountProducts
  );

  const applyAutomaticDiscount = usePageStore(
    (state) => state.applyAutomaticDiscount
  );

  const paymentOption = usePaymentOption();
  const discountCode = usePageStore((state) => state.discountCode);
  const discountAmount = useDiscount();

  const currencyCode = useOfferStore((state) => state.offer?.currency.code);

  const currencySymbol = useOfferStore((state) => state.offer?.currency.symbol);

  const zeroDecimal = isZeroDecimalCurrency(
    currencySymbol ?? CURRENCY_SYMBOL.USD
  );

  const [value, setValue] = useState('');

  const couponState = usePageStore((state) => state.couponState);
  const setCouponState = usePageStore((state) => state.setCouponState);

  const { t } = useTranslation('paymentPage');

  const setPricesDiscount = usePricesStore(
    (state: PricesStore) => state.setDiscount
  );

  const couponBody = {
    paymentPageId,
    productId,
    bumpId,
  };

  const clearValue = (e?: FormEvent) => {
    e?.preventDefault();
    setValue('');
    setCouponState(CouponState.default);
    setDiscount(null);
    setDiscountCode(null);
    setDiscountProducts(null);
  };

  const internalVerifyCoupon = async (e: FormEvent | null = null) => {
    e?.preventDefault();
    try {
      const params = new URLSearchParams(location.search);
      const code = value !== '' ? value : params.get('vcode');

      if (!code) {
        setCouponState(CouponState.inapplicable);
        return;
      }

      const res = await verifyCoupon(accountSlug, code, couponBody);
      const { products } = res;

      if (
        isCouponApplicable(
          res,
          paymentOption?.unit_amount!,
          zeroDecimal,
          currencyCode ?? CURRENCY_CODE.USD
        )
      ) {
        setCouponState(CouponState.success);
        setDiscount(res);
        setDiscountCode(code);
        setDiscountProducts(products ?? []);
      } else {
        setCouponState(CouponState.inapplicable);
        setDiscount(null);
        setDiscountCode(null);
        setDiscountProducts(null);
      }
    } catch (err) {
      invalidDiscountCode();
    }
  };

  const invalidDiscountCode = () => {
    setValue('');
    setCouponState(CouponState.failure);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const vCode = params.get('vcode');
    if (vCode) {
      setValue(vCode);
      setTimeout(() => {
        internalVerifyCoupon();
      }, 500);
    } else {
      applyAutomaticDiscount();
    }
  }, []);

  useEffect(() => {
    if (discountCode) {
      setValue(discountCode);
      setCouponState(CouponState.success);
    } else if (discountCode === '') {
      clearValue();
    }
  }, [discountCode]);

  useEffect(() => {
    setPricesDiscount(discountAmount);
  }, [discountAmount]);

  useEffect(() => {
    value && internalVerifyCoupon();
  }, [paymentOption?.id]);

  if (
    !discountEnabled ||
    (discountCode && couponState !== CouponState.inapplicable)
  ) {
    /* value !== '' && setValue('');
    ![CouponState.default, CouponState.inapplicable].includes(couponState) &&
      setCouponState(CouponState.default); */
    return null;
  }

  return (
    <div
      className={
        value.toLowerCase().includes('os-') ? 'invisible h-0' : 'mt-4 lg:mt-2'
      }
    >
      <div className="px-2 lg:px-0 pb-1 lg:pb-2">
        <form
          onSubmit={
            couponState === CouponState.success
              ? clearValue
              : internalVerifyCoupon
          }
        >
          <div className="flex h-11 justify-between">
            <input
              value={value}
              placeholder={t('promoCode')}
              onChange={(e) => {
                setValue(e.target.value);
                setCouponState(CouponState.default);
              }}
              className="appearance-none border rounded-sm w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              disabled={
                couponState === CouponState.pending ||
                couponState === CouponState.success
              }
            />
            <button
              className="bg-gray-900 text-white min-w-[130px] font-semibold rounded-r-sm disabled:opacity-80"
              disabled={value.length < 3 || couponState === CouponState.pending}
              data-testid="coupon-button"
            >
              <span>
                {couponState === CouponState.success ? t('remove') : t('apply')}
              </span>
            </button>
          </div>
        </form>

        {couponState === CouponState.failure && (
          <p className="coupon__error-message">
            <small>
              <span>{t('invalidDiscount')}</span>
            </small>
          </p>
        )}
        {couponState === CouponState.inapplicable && (
          <p className="coupon__error-message">
            <small>
              <span>{t('inapplicableDiscount')}</span>
            </small>
          </p>
        )}
      </div>
    </div>
  );
};
