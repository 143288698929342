import { IPaymentMethod } from '@/src/components/Checkout/Checkout.types';
import { ICustomization } from './customization';

export interface IProduct {
  id: number;
  name: string;
  price: number;
}

export type StripeProduct = {
  id: string;
  name: string;
  tax_code?: string | null;
};

export enum PaymentPageStatus {
  live = 'live',
  test = 'test',
  disabled = 'disabled',
}

export interface ICurrency {
  code: string;
  symbol: string;
}

export enum PaymentType {
  one_time_fee = 'one_time_fee',
  subscription = 'subscription',
}

export const billingLabels = {
  ['daily']: ' daily',
  ['weekly']: ' weekly',
  ['every 2 weeks']: ' every 2 weeks',
  ['monthly']: '/mo',
  ['quarterly']: ' quarterly',
  ['every 6 months']: ' every 6 months',
  ['annual']: '/yr',
};

export const subscriptionLabels = {
  ['daily']: 'Every day',
  ['weekly']: 'Every week',
  ['every 2 weeks']: 'Every 2 weeks',
  ['monthly']: 'Every month',
  ['quarterly']: 'Every quarter',
  ['every 6 months']: 'Every 6 months',
  ['annual']: 'Every year',
};

export interface IPaymentOption {
  id: number;
  name: string | null;
  payment_type: PaymentType;
  price: number;
  trial_period_price: number | null;
  trial_days: number | null;
  frequency: string | null;
  rebills: number;
  productName?: string;
  unit_amount: number;
  stripe_price_id?: string;
}

export enum PaymentSystems {
  stripe = 'stripe',
  infusionsoft = 'infusionsoft',
  paypal = 'paypal',
  networkmerchants = 'networkmerchants',
}

export interface IPaymentAccountSystem {
  name: PaymentSystems;
}

export interface ICreditcardType {
  id?: string;
  name: string;
  logo: string;
}

export interface IPaymentAccount {
  id: number;
  system: IPaymentAccountSystem;
  available_creditcard_types: ICreditcardType[];
  metadata: {
    payment_method: 'credit_card' | 'paypal';
  };
}

export interface ICheckoutTemplateType {
  id: number;
  name: string;
  prpreviewew: string;
}

export enum BorderStyle {
  dashed = 'dashed',
  solid = 'solid',
  double = 'double',
}

export interface IRGBA {
  r: number;
  g: number;
  b: number;
  a: number;
}

export interface IUpsellCustomize {
  accent_color: IRGBA;
  btn_bg_color: IRGBA;
  btn_text_color: IRGBA;
  confirmation_enabled: boolean;
  description: string;
  img_url: string;
  no_button_text: string;
  title: string;
  video_url: string;
  video_used: boolean;
  yes_button_text: string;
}

export interface IUpsell {
  id: number;
  product: IProduct;
  customize: IUpsellCustomize;
  hash: string;
  settings: {
    enabled: boolean;
    redirect_url: string;
  };
  payment_option: IPaymentOption;
}

export interface IBumpCustomization {
  form_preview: {
    lead_text: string;
    body_text: string;
    intro_text: string;
  };
  border_color: IRGBA;
  text_color: IRGBA;
  border_style: BorderStyle;
  line_thickness: string;
  animated_checkbox: boolean;
  background_color: IRGBA;
}

export interface ITwoColumnBumpCustomization {
  form_preview: {
    product_name: string;
    product_description: string;
    lead_text: string;
  };
  img_link: string;
  border_color: IRGBA;
  text_color: IRGBA;
  border_style: BorderStyle;
  line_thickness: string;
  animated_checkbox: boolean;
  background_color: IRGBA;
}

export interface IBump {
  enabled: boolean;
  product: IProduct;
  customization: IBumpCustomization | ITwoColumnBumpCustomization;
  payment_option: IPaymentOption;
}

export interface IPaymentPage {
  id: number;
  label: string;
  product_name?: string;
  product: IProduct;
  status: PaymentPageStatus;
  currency: ICurrency;
  bump: IBump;
  payment_option: IPaymentOption;
  payment_integrations: IPaymentAccount[];
  checkout_template_type: ICheckoutTemplateType;
  checkout_template_customization: ICustomization;
  quantity_limited: boolean;
  quantity_limit: number | null;
  quantity_remaining: number | null;
  address_required: boolean;
  created_at: number;
  updated_at: number;
  upsells: IUpsell[];
  automaticPaymentMethods?: boolean;
  paymentMethods?: IPaymentMethod[] | null;
}

export interface IStripeConfig {
  public_key: string;
}

export interface IGetPaymentPageResponse {
  payment_page: IPaymentPage;
  integrations: any;
  ipAddress: string;
  upsellId?: string;
  shouldBuyUpsell?: boolean;
}
