import { useTranslation } from 'next-i18next';
import { FieldError } from 'react-hook-form';

const Error = ({ message }) => (
  <p className="text-sm text-pink-600 pt-1">{message}</p>
);

type InputProps = {
  id?: string;
  name?: string;
  type?: string;
  placeholder?: string;
  register?: any;
  registerOptions?: any;
  autocomplete?: string;
  errors?: FieldError | null;
  errorName?: string;
  disabled?: boolean;
};

export const Input = ({
  id,
  name,
  type,
  placeholder,
  register,
  registerOptions,
  autocomplete,
  errors = null,
  errorName,
  disabled = false,
}: InputProps) => {
  const { t } = useTranslation('common');

  return (
    <>
      <div className="floating-label relative group">
        <input
          {...register(id, registerOptions)}
          className={`h-12 shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 invalid:border-pink-500 invalid:text-pink-600 invalid:ring-0 placeholder:text-base ${
            errors?.type ? 'border-pink-500 text-pink-600' : 'text-gray-700'
          }`}
          id={id}
          name={name}
          type={type}
          placeholder={placeholder}
          autoComplete={autocomplete}
          aria-invalid={errors?.type ? 'true' : 'false'}
          disabled={disabled}
          data-testid={name}
        />
        <label className="text-xs px-3" htmlFor={id}>
          {placeholder}
        </label>
      </div>

      {errors?.type === 'required' && (
        <Error message={`${t('isRequired', { errorName })}`} />
      )}
      {errors?.type && errors?.type !== 'required' && (
        <Error message={`${t('isInvalid', { errorName })}`} />
      )}
    </>
  );
};
