import { PAYMENT_TYPE } from '@/src/components/Checkout/Checkout.constants';
import { getOrderCustomFieldsFromQuery } from '@/src/components/Checkout/Checkout.utils';
import {
  resetSessionAndStores,
  triggerGtmAndFbEventsForPayPalPurchase,
} from '@/src/helpers';
import { usePaymentOption } from '@/src/hooks';
import { redirectTo } from '@/src/utils';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import * as PurchaseService from '@/src/services/PurchaseService';
import {
  paypalCancelOrder,
  paypalOrderCheck,
} from '../../common/api/makePurchase';
import { getBonusIds } from './helpers';
import Image from 'next/image';
import { usePageStore } from '@/src/common/zustand';

/**
 * This component is responsible for dynamically setting payment options and props (createOrder, createSubscription, etc.)
 * It also then renders the PayPal button with those props.
 * It needs to be a child of PayPalScriptProvider, so we can use the usePayPalScriptReducer hook.
 *
 * "resetOptions" dispatching is used to set the correct options for the PayPal button
 * based on the payment type of the selected payment option.
 */
const PayPalOptionsHandler = ({
  gdprEnabled,
  thankYouPageUrl,
  paymentIntegrationId,
  paymentPage,
  integrations,
  onClick,
}) => {
  const paymentOption = usePaymentOption();
  const bumpSelected = usePageStore((state) => state.bumpSelected);
  const { t } = useTranslation(['common', 'paymentPage']);
  const [{ isPending, options }, dispatch] = usePayPalScriptReducer();

  useEffect(() => {
    dispatch({
      type: 'resetOptions',
      value: {
        ...options,
        vault: paymentOption?.payment_type === PAYMENT_TYPE.subscription,
        intent:
          paymentOption?.payment_type === 'subscription'
            ? 'subscription'
            : 'capture',
      },
    });
  }, [paymentOption?.payment_type, bumpSelected]);

  if (isPending) {
    return (
      <Image
        width="50"
        height="50"
        src="/static/img/preloader.gif"
        alt="PayPal loading..."
      />
    );
  }

  const params = new URLSearchParams(location.search);

  // Base payload
  const payPalPayloadBase = {
    payment_page_id: paymentPage.id,
    payment_integration_id: paymentIntegrationId,
    custom_fields: getOrderCustomFieldsFromQuery(params),
    active_upsell: false,
    api_endpoint: process.env.API,
    app_env: process.env.APP_ENV,
  };

  const redirectPage = () => {
    resetSessionAndStores();

    try {
      triggerGtmAndFbEventsForPayPalPurchase({
        integrations,
        paymentPage,
        purchasePayload: payPalPayloadBase,
      });
    } catch (err) {
      console.log(err);
    }

    redirectTo(thankYouPageUrl);
  };

  const buttonOptions =
    paymentOption?.payment_type === PAYMENT_TYPE.subscription
      ? {
          createSubscription: async (_, actions): Promise<string> => {
            return window.grecaptcha
              .execute(process.env.RECAPTCHAV3_SITE_KEY, { action: 'submit' })
              .then(async (reCaptchaToken) => {
                return PurchaseService.createPayPalSubscription(
                  payPalPayloadBase,
                  reCaptchaToken,
                  gdprEnabled,
                  actions
                );
              });
          },
          onApprove: async () => {
            redirectPage();
          },
        }
      : {
          createOrder: async () => {
            return window.grecaptcha
              .execute(process.env.RECAPTCHAV3_SITE_KEY, { action: 'submit' })
              .then(async (reCaptchaToken) => {
                return PurchaseService.createPayPalOrder(
                  payPalPayloadBase,
                  reCaptchaToken,
                  gdprEnabled
                );
              });
          },
          onCancel(data) {
            return paypalCancelOrder({
              order_id: data.orderID,
            });
          },

          onApprove(data) {
            return paypalOrderCheck({
              order_id: data.orderID,
              metadata: {
                bonuses: getBonusIds(),
              },
            }).then(() => {
              redirectPage();
            });
          },
        };

  if (
    bumpSelected &&
    paymentOption?.payment_type === PAYMENT_TYPE.subscription
  ) {
    return null;
  }

  return (
    <div className="mt-4 mb-8 w-full sm:w-1/2 md:w-1/3">
      <p className="text-sm mb-2">
        {t('payWithPaypal', { ns: 'paymentPage' })}
      </p>
      <PayPalButtons
        fundingSource={'paypal'}
        style={{ height: 43 }}
        {...buttonOptions}
        onClick={onClick}
        onError={(err) => {
          // TODO - handlePurchaseError(err);
          console.log(err);
        }}
      />
      <p className="text-xs opacity-90 font-semibold mt-6 mb-6">
        &mdash; {t('or')} &mdash;
      </p>
    </div>
  );
};

export default PayPalOptionsHandler;
