import { NamesTemplates } from '../template';

export type changeValue = (value: string) => void;
export type verifyCoupon = () => void;
export type clearValue = () => void;
export type value = string;

export enum CouponState {
  default = 'default',
  pending = 'pending',
  success = 'success',
  failure = 'failure',
  inapplicable = 'inapplicable',
}

export enum DiscountType {
  flat = 'flat',
  percentage = 'percentage',
}

export interface IDiscountData {
  discount: string; // number, api returns string
  discount_type: DiscountType;
  redeemable_from: string | null;
  redeemable_until: string | null;
  redeemable_expires: boolean;
  uses_count: number;
  uses_limit: number;
  recurring: boolean;
  uses_limited: boolean;
  name: string;
  code: string;
  products?: number[];
  encrypted: string;
}

export interface IResponseVerify {
  data: IDiscountData;
}

export interface ICouponState {
  value: value;
  state: CouponState;
  discountData?: IDiscountData;
  error: string | null;
}

export interface IRequestVerifyData {
  code: value;
  payment_option_id: number;
}

export interface ICouponComponent {
  value: value;
  state: CouponState;
  error: string;
  changeValue: changeValue;
  verifyCoupon: verifyCoupon;
  clearValue: clearValue;
  nameTemplate: NamesTemplates;
}

export interface ICouponMapStateToProrpsResult {
  value: value;
  error: string;
  state: CouponState;
}

export interface ICouponMapDispatchToPropsResult {
  changeValue: changeValue;
  verifyCoupon: verifyCoupon;
  clearValue: clearValue;
}

export interface ISmartButton {
  state: CouponState;
  clearValue: clearValue;
}
