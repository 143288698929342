const CartIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="1 -1 100 100"
      className="h-6 w-6 self-center"
    >
      <title>20</title>
      <title>20</title>
      <g>
        <path d="M43.4,71.5c-4,0-7.2,3.3-7.2,7.3s3.2,7.4,7.2,7.4s7.2-3.3,7.2-7.4S47.4,71.5,43.4,71.5z M43.4,84.2c-2.9,0-5.2-2.4-5.2-5.4   s2.3-5.3,5.2-5.3c2.9,0,5.2,2.4,5.2,5.3S46.3,84.2,43.4,84.2z"></path>
        <path d="M80.1,71.5c-4,0-7.2,3.3-7.2,7.3s3.2,7.4,7.2,7.4s7.2-3.3,7.2-7.4S84,71.5,80.1,71.5z M80.1,84.2c-2.9,0-5.2-2.4-5.2-5.4   s2.3-5.3,5.2-5.3s5.2,2.4,5.2,5.3S82.9,84.2,80.1,84.2z"></path>
        <path d="M91,22.7c-0.2-0.3-0.5-0.4-0.8-0.4H29.5l-1.8-5.6c-0.9-2.9-3.6-4.9-6.7-4.9h-9.3c-0.6,0-1,0.4-1,1s0.4,1,1,1h9.3   c2.2,0,4.1,1.4,4.8,3.5l2,6.3c0,0,0,0,0,0L37,52.4c0,0,0,0,0,0l-0.4,1c-1.1,2.5-0.8,5.3,0.7,7.6c1.5,2.3,4,3.6,6.7,3.6h41   c0.6,0,1-0.4,1-1s-0.4-1-1-1h-41c-2,0-3.9-1-5-2.7c-1.1-1.7-1.3-3.8-0.5-5.7l0.5-1.1h36.4c4,0,7.4-2.5,8.6-6.3l7.3-23.2   C91.3,23.3,91.2,23,91,22.7z M82,46.2c-0.9,2.9-3.6,4.9-6.7,4.9H38.7l-8.5-26.8h58.7L82,46.2z"></path>
      </g>
    </svg>
  );
};

export default CartIcon;
