import create from 'zustand';
import { components } from '@/src/schemas/offer';

interface ContrastStore {
  contrastColor: string;
  setContrastColor: (color: components['schemas']['RGBA']) => void;
}

const getContrastColor = (obj: components['schemas']['RGBA']): string => {
  const { r, g, b } = obj;

  if (r === undefined || g === undefined || b === undefined) {
    return 'black';
  }

  const rNorm = r / 255;
  const gNorm = g / 255;
  const bNorm = b / 255;

  const rLum =
    rNorm <= 0.03928 ? rNorm / 12.92 : Math.pow((rNorm + 0.055) / 1.055, 2.4);
  const gLum =
    gNorm <= 0.03928 ? gNorm / 12.92 : Math.pow((gNorm + 0.055) / 1.055, 2.4);
  const bLum =
    bNorm <= 0.03928 ? bNorm / 12.92 : Math.pow((bNorm + 0.055) / 1.055, 2.4);

  const luminance = 0.2126 * rLum + 0.7152 * gLum + 0.0722 * bLum;

  return luminance < 0.5 ? 'white' : 'black';
};

export const useContrastStore = create<ContrastStore>((set) => ({
  contrastColor: 'black',
  setContrastColor: (color: components['schemas']['RGBA']) =>
    set({ contrastColor: getContrastColor(color) }),
}));
