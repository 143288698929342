import { CSSProperties, lazy, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';

import { TermsAndConditions } from '@/components/TermsAndConditions';
import { SubscriptionNotices } from '@/components/SubscriptionNotices';
import { getOfferArgs } from './Offer.helpers';
import { useRouter } from 'next/router';
import { usePageStore, useOfferStore } from '@/common/zustand';

import getDeadlineByEmailDdb from '@/src/common/api/getDeadlineByEmailDdb';
import getDeadlineByEmailEvergreenService from '@/src/common/api/getDeadlineByEmailEvergreenService';
import { OfferStackDeadlineSettings } from '@/src/pages/api/deadline/service';

import { EvergreenDeadline as EvergreenDeadlineType } from '@/pages/api/deadline/email';
import { Offer as OfferType } from '@/src/pages/[host]/[uid]/[slug]';
import { Intro } from '@/src/common/components/Intro';
import OrderBump from '@/src/common/components/OrderBump';
import ContactInformation from '@/src/common/components/ContactInformation';
import Footer from '@/src/common/components/Footer';
import Layout from '../Layout';
import usePricesStore from '../../common/zustand/usePricesStore';
import { useAmounts } from '../../common/hooks';
import { DeadlineType } from '@/src/components/Offer/Offer.enums';
import { TestModeBanner } from '../TestModeBanner';
import { FakeCheckoutForm } from '../FakeCheckoutForm';
import { clearQueryString, clearUrlHash } from '@/src/common/utils';
import { PayPalForm } from '@/src/components/PayPalForm';
import OrderSummary from '@/src/common/components/OrderSummary';
import { format } from 'date-fns';
import { Deadline } from '@/src/components/Deadline';
import { useContrastStore } from '@/src/common/zustand/useContrastStore';
import TextBelowBuyButton from '@/src/common/components/TextBelowBuyButton';
import { getCurrencyCodeValue } from '@/src/common/components/OrderSummary/utils';
import useCurrencyCodeStore from '@/src/common/zustand/useCurrencyCodeStore';

/** This component needs to be lazy-loaded so that we avoid
 * loading it in voma admin (as a part of @voma/offer-preview package)
 * because next/font causes the app to break on runtime (only in voma admin
 * since it's not running on next.js) *
 */
const EvergreenEmailForm = lazy(
  () => import('@/src/components/Deadline/EvergreenEmailForm')
);

const Checkout = dynamic(
  () => import('@/src/components/Checkout').then(({ Checkout }) => Checkout),
  { ssr: false }
);

const Header = dynamic(
  () => import('@/src/components/Header').then((mod) => mod.Header),
  { ssr: false }
);

const Bonuses = dynamic(
  () => import('@/src/components/Bonuses').then((mod) => mod.Bonuses),
  { ssr: false }
);

export interface Props {
  offer: OfferType;
  encryptedOffer: string;
  forceMobile?: boolean;
  previewMode?: boolean;
  isE2ETestOffer: boolean;
}

const Offer: React.FC<Props> = ({
  offer,
  encryptedOffer,
  forceMobile,
  previewMode = false,
  isE2ETestOffer = false,
}: Props): JSX.Element | null => {
  const contactFormRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const [showEmailForm, setShowEmailForm] = useState<boolean>(false);
  const [deadlineFetched, setDeadlineFetched] = useState<boolean>(false);
  const [vEmail, setVemail] = useState<string | undefined>('');

  const setPrices = usePricesStore((state) => state.setPrices);
  const { setContrastColor } = useContrastStore();
  const { setCurrencyCode } = useCurrencyCodeStore();

  const bonuses = useOfferStore((state) => state.offer?.bonuses);

  const amounts = useAmounts();

  useEffect(() => {
    setPrices('amount', amounts);
  }, [amounts]);

  const { push, asPath } = router;
  const { t } = useTranslation(['paymentPage', 'common']);

  const deadlineInfo = usePageStore((state) => state.deadlineInfo);

  const setSelectedPaymentOptionId = usePageStore(
    (state) => state.setSelectedPaymentOptionId
  );
  const setDeadlineInfo = usePageStore((state) => state.setDeadlineInfo);
  const setDeadlineExpired = usePageStore((state) => state.setDeadlineExpired);

  const {
    stripe,
    style,
    desktopPageTemplate,
    intro,
    orderSummary,
    mobileOrderSummary,
    contactInformation,
    addressEnabled,
    bumpEnabled,
    orderBump,
    payPal,
    payPalEnabled,
    termsAndConditions,
    termsAndConditionsEnabled,
    subscriptionNotices,
    footer,
    sidebar,
    deadline,
  } = getOfferArgs(offer);

  const evergreenDeadlineEnabled =
    deadline?.main_offer?.enabled &&
    deadline?.main_offer?.type === DeadlineType.Evergreen;

  const optinToOfferEnabled =
    deadline?.main_offer?.enabled &&
    deadline?.main_offer?.type === DeadlineType.OptinToOffer;

  useEffect(() => {
    if (!router.query.fromUpsell) {
      clearUrlHash();
      clearQueryString();
    }
  }, [router.query]);

  useEffect(() => {
    useOfferStore.setState({ offer, encryptedOffer });
    setContrastColor(offer.customization.background_color);
    setCurrencyCode(getCurrencyCodeValue(offer?.currency.code ?? ''));
    const { id: initialPaymentOptionId } =
      offer.payment_options.find((option) => option.default) ||
      offer.payment_options[0];

    setSelectedPaymentOptionId(initialPaymentOptionId!);

    const params = new URLSearchParams(location.search);

    if (params.get('reset')) {
      push(asPath.split('?')[0]);
      return;
    }

    const vEmail = params.get('vemail');

    if (vEmail) {
      setVemail(vEmail);
    }

    if (previewMode) {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      setDeadlineInfo({ deadlineUtc: format(tomorrow, 'yyyy-MM-dd HH:mm:ss') });
    }
  }, []);

  const checkDeadline = (emailInput: string) => {
    getDeadlineByEmailDdb(offer.id, emailInput).then((response) => {
      if (response?.error) {
        onSubmitEmailErrorDdb();
        return;
      }
      const { email, deadlineUtc } = response as EvergreenDeadlineType;
      if (
        email &&
        deadlineUtc &&
        (deadlineInfo?.email !== email ||
          deadlineInfo?.deadlineUtc !== deadlineUtc)
      ) {
        setDeadlineInfo({ email, deadlineUtc });
        setDeadlineFetched(true);
        setShowEmailForm(false);
      }
    });
  };

  useEffect(() => {
    if ((evergreenDeadlineEnabled || optinToOfferEnabled) && !deadlineFetched) {
      if (vEmail) {
        setDeadlineInfo(null);
        setDeadlineExpired(false);
        checkDeadline(vEmail as string);
        return;
      }

      if (deadlineInfo?.email) {
        checkDeadline(deadlineInfo.email);
        return;
      }

      if (deadlineInfo?.expired || previewMode) {
        return;
      }

      setShowEmailForm(true);
    }
  }, [
    evergreenDeadlineEnabled,
    optinToOfferEnabled,
    deadlineFetched,
    deadlineInfo,
    vEmail,
    previewMode,
  ]);

  const onSubmitEmail = async (email: string, inEu: boolean, gdpr: boolean) => {
    const reCaptchaToken = await window.grecaptcha.execute(
      process.env.RECAPTCHAV3_SITE_KEY,
      {
        action: 'optin',
      }
    );

    return await getDeadlineByEmailEvergreenService({
      deadline: deadline?.main_offer as OfferStackDeadlineSettings,
      in_eu: inEu,
      gdpr,
      account_id: offer.account.id,
      offerId: offer.id,
      email,
      recaptcha_token: reCaptchaToken,
    });
  };

  const onSubmitEmailSuccess = (evergreenDeadline: EvergreenDeadlineType) => {
    const { email, deadlineUtc } = evergreenDeadline;
    setShowEmailForm(false);
    setDeadlineInfo({
      email,
      deadlineUtc,
    });
  };

  const onSubmitEmailError = () => {
    setShowEmailForm(true);
  };

  const onSubmitEmailErrorDdb = () => {
    setDeadlineInfo(null);

    // Remove vemail from URL
    const { query } = router;
    const params = new URLSearchParams(query as Record<string, string>);
    params.delete('vemail');

    // These are Next.js reserved query params that we don't want to include on refresh
    params.delete('host');
    params.delete('uid');
    params.delete('slug');

    // Refresh the page with the new URL
    const currentUrl = window.location.href.split('?')[0];
    const paramString = params.toString();

    const newUrl = `${currentUrl}${
      paramString.length > 0 ? `?${paramString}` : ''
    }`;

    window.location.replace(newUrl);
  };

  return (
    <>
      {showEmailForm && !vEmail && (
        <EvergreenEmailForm
          onSubmit={onSubmitEmail}
          onSuccess={onSubmitEmailSuccess}
          onError={onSubmitEmailError}
          optinForm={offer?.deadline?.optin_form}
        />
      )}
      <div
        className={
          showEmailForm
            ? 'blur-sm pointer-events-none'
            : 'blur-none pointer-events-auto'
        }
        style={{
          ...(style as CSSProperties),
          pointerEvents: showEmailForm ? 'none' : 'auto',
        }}
      >
        {offer.status !== 'live' && (
          <TestModeBanner
            textBefore={t('testModeBefore')}
            textAfter={t('testModeAfter')}
          />
        )}
        <Header />
        <Layout
          account={offer.account.slug}
          forceMobile={forceMobile}
          desktopPageTemplate={desktopPageTemplate}
          intro={
            intro.headline?.description || intro.headline?.title ? (
              <Intro {...intro} />
            ) : null
          }
          orderSummary={<OrderSummary {...orderSummary}></OrderSummary>}
          mobileOrderSummary={
            <OrderSummary {...mobileOrderSummary}></OrderSummary>
          }
          bonuses={
            !!bonuses?.length ? <Bonuses forceMobile={forceMobile} /> : null
          }
          contactInformation={
            <div ref={contactFormRef}>
              <ContactInformation
                {...contactInformation}
                evergreenDeadlineEnabled={
                  !!(evergreenDeadlineEnabled || optinToOfferEnabled)
                }
                addressEnabled={addressEnabled}
              />
            </div>
          }
          deadline={
            <div
              onClick={() =>
                contactFormRef?.current?.scrollIntoView({ behavior: 'smooth' })
              }
            >
              <Deadline />
            </div>
          }
          addressEnabled={addressEnabled}
          orderBump={bumpEnabled ? <OrderBump {...orderBump} /> : <></>}
          checkoutForm={
            previewMode || showEmailForm ? (
              <FakeCheckoutForm
                addressEnabled={addressEnabled}
                forceMobile={forceMobile}
                billingAddress={t('billingAddress', { ns: 'common' })}
                paymentMethod={t('paymentMethod', { ns: 'common' })}
                payPalElement={
                  !!payPalEnabled ? (
                    <PayPalForm addressEnabled={addressEnabled} {...payPal} />
                  ) : null
                }
                btnLabel={offer.customization.buy_button.label}
              />
            ) : (
              <Checkout
                {...stripe}
                addressEnabled={addressEnabled}
                payPalElement={
                  !!payPalEnabled ? (
                    <PayPalForm addressEnabled={addressEnabled} {...payPal} />
                  ) : null
                }
                isE2ETestOffer={isE2ETestOffer}
              />
            )
          }
          termsAndConditions={
            termsAndConditionsEnabled ? (
              <TermsAndConditions {...(termsAndConditions as any)} />
            ) : (
              <></>
            )
          }
          subscriptionNotices={<SubscriptionNotices {...subscriptionNotices} />}
          footer={<Footer {...footer} />}
          sidebar={sidebar}
          previewMode={previewMode}
          // The props below should be removed and replaced with reading from the offer store (useOfferStore)
          paymentPageId={offer.id}
          liveMode={offer.status === 'live'}
          accountId={offer.account.slug}
          textBelowBuyButton={
            <TextBelowBuyButton
              text={offer.customization.text_below_buy_button}
            />
          }
          mirrored={offer.customization.mirrored}
        />
      </div>
    </>
  );
};

export default Offer;
