import { useOfferStore, usePageStore } from '@/src/common/zustand';
import usePricesStore from '@/src/common/zustand/usePricesStore';
import { PaymentType } from '@/src/components/Checkout/Checkout.enums';
import { denominate } from '../common/components/OrderSummary/utils';
import { isZeroDecimalCurrency } from '../helpers';
import usePaymentOption from './usePaymentOption.hook';

const useTodayTotal = () => {
  const currency = useOfferStore((state) => state.offer?.currency.symbol);
  const denominateCurr = denominate(currency);

  const bumpSelected = usePageStore((state) => state.bumpSelected);

  const bump = usePricesStore((state) => state.bump);
  const discountAmount = usePricesStore((state) => state.discount);
  const tax = usePricesStore((state) => state.tax);

  const taxCalcOTP = usePageStore((state) => state.taxCalcOTP);
  const getOtpPrices = usePageStore((state) => state.getOtpPrices);

  const paymentOption = usePaymentOption();

  const mainAmount = paymentOption?.price;

  let subtotal = 0,
    discount = 0,
    taxes = 0,
    total = 0;

  if (paymentOption?.payment_type === PaymentType.subscription) {
    // No trial
    if (paymentOption?.trial_days === 0) {
      discount = denominateCurr(discountAmount);
      taxes = denominateCurr(tax);
      subtotal = mainAmount! + denominateCurr(bumpSelected ? bump.amount : 0);
      total = discount <= subtotal ? subtotal - discount : subtotal;
    }
    // Free trial
    if (
      !!paymentOption?.trial_days &&
      paymentOption?.trial_period_price !== null &&
      paymentOption?.trial_days > 0 &&
      typeof paymentOption?.trial_period_price !== 'undefined' &&
      +paymentOption?.trial_period_price === 0
    ) {
      discount = bumpSelected ? denominateCurr(discountAmount) : 0;
      taxes = bumpSelected ? denominateCurr(tax) : 0;
      subtotal = bumpSelected ? denominateCurr(bump.amount) : 0;
      total = discount <= subtotal ? subtotal - discount : subtotal;
    }
    // Paid trial
    if (
      !!paymentOption?.trial_days &&
      !!paymentOption?.trial_period_price &&
      paymentOption?.trial_days > 0 &&
      +paymentOption?.trial_period_price > 0
    ) {
      discount = denominateCurr(discountAmount);
      taxes = denominateCurr(tax);
      subtotal =
        +paymentOption?.trial_period_price +
        (bumpSelected ? denominateCurr(bump.amount) : 0);
      total = discount <= subtotal ? subtotal - discount : subtotal;
    }

    const index = isZeroDecimalCurrency(currency) ? 1 : 100;
    total = Math.round(total * index);
  }

  // OTP
  if (paymentOption?.payment_type === PaymentType.one_time_fee) {
    const { main_tax, bump_tax } = taxCalcOTP;

    const otpPrices = getOtpPrices();

    if (otpPrices) {
      discount = discountAmount;
      const { mainUnitAmount, bumpUnitAmount } = otpPrices;

      subtotal =
        mainUnitAmount + (bumpSelected && bumpUnitAmount ? bumpUnitAmount : 0);
      taxes = (main_tax ?? 0) + (bumpSelected && bump_tax ? bump_tax : 0);

      // Ignore discount if it's greater than the total amount
      total = (discount <= subtotal ? subtotal - discount : subtotal) + taxes;
    }
  }

  return {
    subtotal,
    discount,
    taxes,
    total,
  };
};

export default useTodayTotal;
