import { FC, useMemo } from 'react';
import {
  denominate,
  formatPrice,
  getSubStartDate,
  isSubscription,
  withDiscount,
} from './utils';
import { useTranslation } from 'next-i18next';
import usePricesStore from '../../zustand/usePricesStore';
import { usePageStore } from '../../zustand';
import { DiscountType } from '../../../modules/payment-page/models/elements/coupon';
import { components } from '@/src/schemas/offer';
import { SubscriptionIcon } from '@/src/components/PaymentOptions/PaymentOption';
import { useTrialDescription } from '@/src/hooks';
import { useMedia } from '../../hooks';
import { TARGET_MEDIA_WIDTH } from '@/src/components/Layout/Layout.constants';
import classNames from 'classnames';

type SubscriptionInfoProps = {
  option: components['schemas']['PaymentOption'];
  currency: components['schemas']['Currency']['symbol'];
  language: string;
};

const SubscriptionInfo: FC<SubscriptionInfoProps> = ({
  option,
  currency,
  language,
}): JSX.Element => {
  const { t } = useTranslation(['paymentPage', 'prices']);

  const tax = usePricesStore((state) => state.tax);
  const discount = usePricesStore((state) => state.discount);
  const discountData = usePageStore((state) => state.discount);
  const discountAmount = Number(discountData?.discount ?? 0);
  const subscription = usePricesStore((state) => state.main.subscription);
  const discountRecurring = discountData?.recurring ?? false;
  const isFlatDiscount = usePageStore(
    (state) => state.discount?.discount_type === DiscountType.flat
  );

  const startDate = useMemo(() => {
    const trialDays = option.trial_days ? option.trial_days : 0;
    return getSubStartDate(trialDays, language);
  }, [option.trial_days, language]);

  const taxInfo = useMemo(() => {
    if (tax === 0) return '';
    return `${t('plus applicable tax', { ns: 'prices' })}`;
  }, [tax]);

  const origPrice = +(option.price ?? 0);
  const rebills = +(option.rebills ?? 0);

  const trialDescription = useTrialDescription(option, currency);
  const isSub = isSubscription(option.payment_type);

  const isMobile = useMedia(TARGET_MEDIA_WIDTH);

  const baseSubscriptionPrice = useMemo(() => {
    if (subscription.discount > 0 && !discountRecurring) {
      if (isFlatDiscount) {
        return origPrice - discountAmount;
      }
      return denominate(currency)(subscription.amount - subscription.tax);
    }

    if (discountRecurring) {
      return isFlatDiscount
        ? origPrice - discountAmount
        : withDiscount(discountData!, origPrice);
    }

    return origPrice;
  }, [
    subscription.discount,
    option.price,
    subscription.amount,
    discount,
    discountAmount,
  ]);

  const showTrialDescription =
    isSub && !!option.trial_days && +option.trial_days > 0;

  return (
    <div className={classNames(isMobile && !showTrialDescription && 'mt-4')}>
      {showTrialDescription && (
        <div className="pb-2 mt-4">
          <div className="mt-2 text-sm">
            <b>{trialDescription}</b>
            <span>{` - ${t('Ends')} ${startDate}`}</span>
          </div>
        </div>
      )}
      <div
        className={classNames(
          'text-sm bg-voma-gray-color px-4 py-2 flex',
          !isMobile && 'mb-6',
          !showTrialDescription && 'mt-4'
        )}
      >
        <div className="relative w-[24px]">
          <SubscriptionIcon width={24} height={20} />
        </div>
        <p className="ml-3 flex-auto">
          {rebills == 0 && (
            <>
              <b>{t('subscription', { ns: 'prices' })}</b>
              &nbsp;- {t(`period.${option.frequency}`, { ns: 'prices' })}&nbsp;
              {t('starting', { ns: 'prices' })} {`${startDate}.`}
            </>
          )}
          {rebills > 0 && (
            <>
              <b>{t('splitPay', { ns: 'prices' })}</b>
              &nbsp;-&nbsp;{option.rebills}&nbsp;
              {(!discount || discountRecurring) &&
                `${t('totalPaymentsOf', { ns: 'prices' })} `}
              {!!(discount && !discountRecurring) &&
                `${t('totalPayments', { ns: 'prices' })}.`}
              {(!discount || discountRecurring) &&
                `${currency}${formatPrice(baseSubscriptionPrice)}${t(
                  `pricePeriod.${option.frequency}`,
                  {
                    ns: 'prices',
                  }
                )}.`}
            </>
          )}
          {!!taxInfo && !!discountRecurring && (
            <span>
              {' '}
              Base amount of
              {baseSubscriptionPrice < origPrice ? (
                <>
                  {' '}
                  <del>
                    {currency}
                    {formatPrice(option.price ?? 0)}
                  </del>
                  {` ${currency}${formatPrice(baseSubscriptionPrice)}`}
                </>
              ) : (
                ` ${currency}${formatPrice(option.price ?? 0)}`
              )}
              {t(`pricePeriod.${option.frequency}`, {
                ns: 'prices',
              })}
              {taxInfo.length > 0 && ` ${taxInfo}`}.
            </span>
          )}
          {!!taxInfo &&
            subscription.discount === 0 &&
            !isFlatDiscount &&
            !discountRecurring && (
              <span>
                {' '}
                Base amount of {currency}
                {formatPrice(option.price ?? 0)}
                {t(`pricePeriod.${option.frequency}`, {
                  ns: 'prices',
                })}
                {taxInfo.length > 0 && ` ${taxInfo}`}.
              </span>
            )}
          {subscription.discount > 0 && !discountRecurring && (
            <span>
              {' '}
              First payment of {currency}
              {formatPrice(baseSubscriptionPrice)}, then {currency}
              {formatPrice(option.price ?? 0)}
              {t(`pricePeriod.${option.frequency}`, {
                ns: 'prices',
              })}
              {taxInfo.length > 0 && ` (${taxInfo})`}.
            </span>
          )}
        </p>
      </div>
    </div>
  );
};

export default SubscriptionInfo;
