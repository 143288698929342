import { FC } from 'react';

const SubscriptionIcon: FC<{
  width?: number;
  height?: number;
  color?: string;
}> = ({ width = 19, height = 17, color = '#000000' }): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.07786 13.0239C3.75971 13.0239 1.88311 11.0001 1.88311 8.50006C1.88311 6.00004 3.75971 3.97621 6.07786 3.97621H9.54405L8.41809 5.19051C8.24147 5.38099 8.24147 5.6667 8.41809 5.85718C8.5064 5.95242 8.61679 6.00004 8.72718 6.00004C8.83757 6.00004 8.94796 5.95242 9.03627 5.85718L10.9129 3.83336C11.0895 3.64288 11.0895 3.35716 10.9129 3.16668L9.03627 1.14286C8.85964 0.952381 8.59471 0.952381 8.41809 1.14286C8.24147 1.33334 8.24147 1.61905 8.41809 1.80953L9.54405 3.02383H6.07786C3.274 3.02383 1 5.47623 1 8.50006C1 11.5239 3.274 13.9763 6.07786 13.9763C6.32071 13.9763 6.51941 13.762 6.51941 13.5001C6.51941 13.2382 6.32071 13.0239 6.07786 13.0239Z"
        fill={color}
        stroke={color}
        strokeWidth="0.3"
      />
      <path
        d="M12.9221 3.02369C12.6793 3.02369 12.4806 3.23798 12.4806 3.49988C12.4806 3.76179 12.6793 3.97608 12.9221 3.97608C15.2403 3.97608 17.1169 5.99991 17.1169 8.49993C17.1169 11 15.2403 13.0238 12.9221 13.0238H9.78711L10.9131 11.8095C11.0897 11.619 11.0897 11.3333 10.9131 11.1428C10.7364 10.9523 10.4715 10.9523 10.2949 11.1428L8.41829 13.1666C8.24167 13.3571 8.24167 13.6428 8.41829 13.8333L10.2949 15.8571C10.3832 15.9524 10.4936 16 10.604 16C10.7144 16 10.8248 15.9524 10.9131 15.8571C11.0897 15.6667 11.0897 15.3809 10.9131 15.1905L9.78711 13.9762H12.9221C15.726 13.9762 18 11.5238 18 8.49993C18 5.47609 15.726 3.02369 12.9221 3.02369Z"
        fill={color}
        stroke={color}
        strokeWidth="0.3"
      />
    </svg>
  );
};

export default SubscriptionIcon;
