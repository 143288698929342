import { FC } from 'react';

const DESCRIPTION_LIMIT = 78;

const Description: FC<{ description: string }> = ({
  description,
}): JSX.Element => (
  <p className="text-base mt-1 w-fit tracking-[-0.32px] lg:tracking-normal leading-[16.8px] lg:leading-[19px] font-[Lato] max-w-[270px] break-words whitespace-normal">
    {description.slice(0, DESCRIPTION_LIMIT)}
  </p>
);

export default Description;
