import { useEffect, useState } from 'react';
import { usePageStore } from '../zustand';
import { isSubscription } from '../components/OrderSummary/utils';
import usePricesStore from '../zustand/usePricesStore';
import * as SubscriptionPreviewService from '@/src/services/SubscriptionPreviewService';
import { isStripeTaxEnabled } from '@/src/helpers';
import { usePaymentOption } from '@/src/hooks';

type PreviewItem = { price: string; reference: string };

export type SubscriptionAmounts = {
  mainPrice: number;
  trialPrice: number;
  discount: number;
  tax: number;
  mainTax: number;
  trialTax: number;
  mainDiscount: number;
  addItems: PreviewItem[];
  allItems: PreviewItem[];
};

const useSubscriptionPreview = (): SubscriptionAmounts | null => {
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [previewAmounts, setPreviewAmounts] =
    useState<SubscriptionAmounts | null>(null);

  const discountData = usePageStore((state) => state.discount);
  const isAddressStateValid = usePageStore(
    (state) => state.addressState.isValid
  );
  const addressLine1 = usePageStore((state) => state.addressValues?.line1);
  const bumpSelected = usePageStore((state) => state.bumpSelected);
  const isTaxAvailable = isStripeTaxEnabled();
  const setPricesLoading = usePricesStore((state) => state.setLoading);

  const paymentOption = usePaymentOption();

  const setLatestPreview = usePricesStore((state) => state.setLatestPreview);

  useEffect(() => {
    if (isAddressStateValid || bumpSelected || !!discountData?.discount) {
      setIsDirty(true);
    }

    if (!isSubscription(paymentOption?.payment_type!) || !isDirty) {
      setPreviewAmounts(null);
      return;
    }

    setPricesLoading(true);

    SubscriptionPreviewService.getPreview(isTaxAvailable, isAddressStateValid)
      .then((data) => {
        if (!data) {
          setPricesLoading(false);
          setPreviewAmounts(null);
          setLatestPreview({
            postal_code: '',
            state: '',
            country: '',
            error: true,
          });
          return;
        }

        if (
          data?.upcoming_invoice?.automatic_tax?.status.includes(
            'requires_location_inputs'
          )
        ) {
          setLatestPreview({
            postal_code:
              data?.upcoming_invoice?.customer_address?.postal_code ?? '',
            state: data?.upcoming_invoice?.customer_address?.state ?? '',
            country: data?.upcoming_invoice?.customer_address?.country ?? '',
            error: true,
          });
        }

        const subPriceId = data.subscription_items[0]?.price;
        const trialPriceId =
          data.extra_invoice_items?.find(
            (inv) => inv.reference === 'paid_trial'
          )?.price ?? '';
        const bumpPriceId =
          data.extra_invoice_items?.find(
            (inv) => inv.reference === 'bump_offer'
          )?.price ?? '';

        function getSubPrice() {
          const item = data?.upcoming_invoice.lines.data.find(
            (item) => item.price.id === subPriceId
          );

          let discount = item?.discount_amounts?.[0]?.amount ?? 0;
          let tax = item?.tax_amounts?.[0]?.amount ?? 0;

          return {
            price: paymentOption?.unit_amount,
            tax,
            discount,
            total: paymentOption?.unit_amount! + tax - discount,
          };
        }

        function getItemPrice(priceId: string | false) {
          const item = data?.upcoming_invoice.lines.data.find(
            (item) => item.price.id === priceId
          );

          let price = item?.amount ?? 0;
          let discount = item?.discount_amounts?.[0]?.amount ?? 0;
          let tax = item?.tax_amounts?.[0]?.amount ?? 0;

          return {
            price,
            tax,
            discount,
            total: price + tax - discount,
          };
        }

        setPreviewAmounts({
          mainPrice: getSubPrice().total,
          trialPrice: getItemPrice(trialPriceId).total,
          discount:
            data.upcoming_invoice.total_discount_amounts?.reduce(
              (acc, discount) => acc + discount.amount,
              0
            ) ?? 0,
          tax:
            data.upcoming_invoice.total_tax_amounts?.reduce(
              (acc, tax) => acc + tax.amount,
              0
            ) ?? 0,
          mainTax: getSubPrice().tax,
          trialTax: getItemPrice(trialPriceId).tax,
          mainDiscount: getSubPrice().discount,
          addItems: [trialPriceId, bumpPriceId]
            .filter((item) => !!item)
            .map((item) => ({
              price: item,
              reference: item === trialPriceId ? 'paid_trial' : 'bump_offer',
            })),
          allItems: [data?.subscription_items[0], ...data.extra_invoice_items],
        });

        setLatestPreview({
          postal_code:
            data?.upcoming_invoice?.customer_address?.postal_code ?? '',
          state: data?.upcoming_invoice?.customer_address?.state ?? '',
          country: data?.upcoming_invoice?.customer_address?.country ?? '',
          error:
            data?.upcoming_invoice?.automatic_tax?.status.includes(
              'requires_location_inputs'
            ) ?? false,
        });
        setPricesLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setPreviewAmounts(null);
        setLatestPreview({
          postal_code: '',
          state: '',
          country: '',
          error: true,
        });
        setPricesLoading(false);
      });
  }, [
    paymentOption,
    isAddressStateValid,
    addressLine1,
    discountData,
    bumpSelected,
    isDirty,
  ]);

  return previewAmounts;
};

export default useSubscriptionPreview;
